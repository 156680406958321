import React from 'react'
import spinnerGif from '../../assets/images/spinner.gif'
import './Spinner.scss'

const Spinner = ({ 
  message = undefined,
}) => {
  
  return (
    <div className="spinner__wrapper">
      <img
        src={spinnerGif}
        className="spinner__spinner"
        alt="loading..."
      />
      {message && (
        <span className="spinner__message">
          {message}
        </span>
      )}
    </div>
  )
}

export default Spinner