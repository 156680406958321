import { DataStore } from '@reams/elias-store'
import { Facility } from "models"
import { throttledRefreshImages } from 'helpers/imageHelpers'

const handleIncomingFacilities = (incomingFacilities, setFacilities) => {
  const facilityObj = {}

  incomingFacilities.forEach(facility => {
    facilityObj[facility.id] = facility
  })

  setFacilities(facilityObj)
}

export const getDataStoreFacilities = ({
  setFacilities, 
  tenantId, 
  allocatedFacilities,
  nonAllocatedActiveFacilities = []
}) => (
  DataStore.observeQuery(Facility, (x) => {
    const { sites, regions, facilities } = allocatedFacilities;

    const facilitiesList = [ ...new Set([ ...facilities, ...nonAllocatedActiveFacilities ])]

    if (
      sites.length === 0 && 
      regions.length === 0 && 
      facilities.length === 0
    ) {
        return x.tenantId("eq", tenantId);
    }

    return x.tenantId("eq", tenantId).or((x) => {
      let condition = x;

      for (let i = 0; i <= regions.length; i++) {
        const regionId = regions[i];
        condition = condition.regionId("eq", regionId);
      }

      for (let i = 0; i <= sites.length; i++) {
        const siteId = sites[i];
        condition = condition.siteId("eq", siteId);
      }

      for (let i = 0; i <= facilitiesList.length; i++) {
        const facilityId = facilitiesList[i];
        condition = condition.id("eq", facilityId);
      }
      
      return condition;
    })
  })
  .subscribe(async({items, isSynced}) => {
    if (!items) return

    if (isSynced) {
      throttledRefreshImages(items);
    }

    handleIncomingFacilities(items, setFacilities)
  })
)