import { useEffect, useMemo, useState } from "react";
import { Table, Typography, Descriptions, Alert } from "antd";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { set as localDbSet, get as localDbGet } from "idb-keyval";
import cx from "classnames";
import ItemDrawer from "components/ItemDrawer/ItemDrawer";
import { FacilityIcon, FloorIcon, EditIcon, CheckIcon } from "components/Icons";
import DataViewSwitch from "components/DataViewSwitch/DataViewSwitch";
import GridView from "components/GridView/GridView";
import { renderUpdatedAt } from "helpers/generalHelpers";
import { Status, mappingStatusText } from "helpers/statusHelpers";
import { DEFAULT_NUMBER_OF_ITEMS_IN_VIEW } from "config";
import FilterHeader from "components/FilterHeader/FilterHeader";
import TableColumnTitle from "components/TableColumnTitle/TableColumnTitle";
import Spinner from "components/Spinner/Spinner";
import FacilitySwitcher from "./FacilitySwitcher";
import { HiddenActionsInDropdown } from "components/ButtonPair/ActionsDropdown";

import "./HomePage.scss";
import "../../styles/customLinks.scss";
import { customFacetList, facilityDefinition } from "helpers/drawerData";
import {
  fetchFilterDescription,
  filterItem,
} from "helpers/filters/itemFilterHelpers";
import {
  buildFilterableTreeByFacet,
  buildRegionFilterableTree,
  treeObject,
} from "helpers/filters/assetFilterHelpers";
import { facilityAssetCount, facilityFloorAndSpaceCount } from "helpers/asset/assetHelpers";
import { useEntityData } from "EntityDataContext/EntityDataContext";

export default function HomePage({
  addPageToHistory,
  pageHeader,
}) {
  useEffect(() => {
    addPageToHistory("Home");

    if (localStorage.getItem("InspectionsItemCountInView")) {
      setItemCountInView(localStorage.getItem("InspectionsItemCountInView"));
    }
  }, []);

  const ASCENDING_ORDER_KEY = "ascend";
  
  const { syncStatusHook: { isOnline, isOutboxEmpty }, tableViewState, tenantData, regions, spaces, floors, assets, sites, facilities, facilitiesToDownload} = useEntityData()

  const [sortBy, setSortBy] = useState("name");
  const [sortOrder, setSortOrder] = useState(ASCENDING_ORDER_KEY);
  const [dataSwitch, setDataSwitch] = tableViewState;
  const [itemCountInView, setItemCountInView] = useState(
    DEFAULT_NUMBER_OF_ITEMS_IN_VIEW
  );
  const [selectedFacility, setSelectedFacility] = useState();
  const [isAcknowledged, setIsAcknowledged] = useState(false);
  const [facilityCountWarning, setFacilityCountWarning] = useState(false);
  const [updatedFacility, setUpdatedFacility] = useState([]);
  const [filters, setFilters] = useState([]);

  const setFiltersNode = (val) => {
    const filterData = {};
    let desc = val;

    desc = fetchFilterDescription({filter: desc, facets, facilities, regions, sites});

    filterData.id = val;
    filterData.description = desc;

    setFilters((old) => {
      if (old.find((o) => o.id === filterData.id)) {
        return [...old];
      }
      return [...old, filterData];
    });
  };

  const onFiltersChange = (node) => {
    // from select fields
    if (Array.isArray(node)) {
      node.map((node) => setFiltersNode(node));
      return;
    }

    // from option fields
    setFiltersNode(node.value);
  };

  const onFilterClose = (filter) => {
    setFilters((old) => {
      return old.filter((f) => f.id !== filter);
    });
  };

  const facets = useMemo(() => {
    return customFacetList.filter(
      (facet) =>
        facilityDefinition.facets?.includes(facet.facetId) ||
        facet.facetId === "status"
    );
  }, [customFacetList, facilityDefinition]);

  const facilityIds = Object.keys(facilities)

  const enhancedFacilities = useMemo(() => {
    const tmp = facilityIds.map((facilityId) => {
      const facility = facilities[facilityId]
      const isEnabled = facilitiesToDownload?.includes(facility.id);
      const site = sites[facility.siteId]

      const spaceCount = isEnabled ? facilityFloorAndSpaceCount({
        floors: floors?.groupByFacility?.[facilityId] || [], 
        spaces,
        entities: {spaces: true}
      }) : "-"

      return {
        ...facility,
        assetCount: isEnabled ? 
          facilityAssetCount(assets.assetTree[facility.id]) 
          : "-",
        spaceCount: spaceCount.spaces,
        postcode: facility?.facets?.postcode,
        siteName: site ? site.name : "",
      };
    });

    return filterItem(tmp, filters, facets);
  }, [facilities, filters, spaces, assets]);

  const filterTreeData = useMemo(() => {
    // search through facets.json and find appropriate facets
    const filterableFacets = facets.filter((i) => i.filterable);

    const facilitiesArray = Object.values(facilities)
    const sitesArray = Object.values(sites)

    const facetFilters = filterableFacets.map((i) => {
      return buildFilterableTreeByFacet(facilitiesArray, i, onFiltersChange);
    });

    const regionFilters = !regions.ids || !facilitiesArray.length || !sitesArray.length
    ? treeObject("Location", "location")
    : buildRegionFilterableTree({
      regions, 
      sites: sitesArray, 
      facilities: facilitiesArray
    });

    return [...facetFilters, regionFilters].sort((a, b) => a.title < b.title ? -1 : 1);
  }, [facilities, regions, sites]);

  const showFacilityInfo = async () => {
    const res = await localDbGet("facility-alert-closed");
    setIsAcknowledged(res);
  };

  useEffect(() => {
    showFacilityInfo();
  }, []);

  const checkFacilityName = async (facility) => {
    if (!updatedFacility?.includes(facility.name)) {
      setUpdatedFacility([...updatedFacility, facility.name]);
    }
  };

  const getCompareSortOrder = (a, b, sortAscending) => {
    let sortOrderResult = null;
    const isDownloadedFacilityAorB = (a, b) =>
      facilitiesToDownload.includes(a.id) ||
      facilitiesToDownload.includes(b.id);

    const isDownloadedFacilityAandB = (a, b) =>
      facilitiesToDownload.includes(a.id) &&
      facilitiesToDownload.includes(b.id);

    if (sortAscending) {
      if (isDownloadedFacilityAorB(a, b) && !isDownloadedFacilityAandB(a, b)) {
        sortOrderResult = facilitiesToDownload.includes(a.id) ? -1 : 1;
      }
    } else {
      if (isDownloadedFacilityAorB(a, b) && !isDownloadedFacilityAandB(a, b)) {
        sortOrderResult = facilitiesToDownload.includes(a.id) ? 1 : -1;
      }
    }

    return sortOrderResult;
  };

  const columnTitles = {
    assetCount: "Assets",
    updatedAt: "Last Edited",
    siteId: "Site",
  };

  const tableColumnTitleProps = {
    sortBy,
    setSortBy,
    sortOrder,
    setSortOrder,
    columnTitles,
  };

  const FACILITY_COLUMNS = [
    {
      title: "Your Facilities",
      key: "activate",
      fixed: "left",
      render: (facility) => (
        <FacilitySwitcher
          setFacilityCountWarning={() => setFacilityCountWarning(true)}
          facility={facility}
        />
      ),
    },
    {
      title: <TableColumnTitle {...tableColumnTitleProps} dataIndex="name" />,
      dataIndex: "name",
      key: "Name",
      fixed: "left",
      sorter: (a, b) => {
        const sortAscending = sortOrder === ASCENDING_ORDER_KEY;

        return getCompareSortOrder(a, b, sortAscending)
          ? getCompareSortOrder(a, b, sortAscending)
          : a.name.localeCompare(b.name?.toLowerCase());
      },
      render: (name, facility) =>
        facilitiesToDownload.includes(facility.id) ? (
          <Link className="table-name-link" to={`/facility/${facility.id}`}>
            {name}
          </Link>
        ) : (
          name
        ),
      sortOrder: sortBy === "name" ? sortOrder : false,
    },
    {
      title: <TableColumnTitle {...tableColumnTitleProps} dataIndex="status" />,
      dataIndex: "status",
      key: "Status",
      sorter: (a, b) => {
        const sortAscending = sortOrder === ASCENDING_ORDER_KEY;
        const aRenderedStatusText = mappingStatusText[a.status];
        const bRenderedStatusText = mappingStatusText[b.status];

        return getCompareSortOrder(a, b, sortAscending)
          ? getCompareSortOrder(a, b, sortAscending)
          : aRenderedStatusText < bRenderedStatusText
          ? -1
          : 1;
      },
      render: (status) => <Status status={status} />,
      sortOrder: sortBy === "status" ? sortOrder : false,
    },
    {
      title: <TableColumnTitle {...tableColumnTitleProps} dataIndex="siteName" />,
      dataIndex: "siteName",
      key: "Site",
      sorter: (a, b) => {
        const sortAscending = sortOrder === ASCENDING_ORDER_KEY;
        const siteNameA = a.siteName;
        const siteNameB = b.siteName;

        return getCompareSortOrder(a, b, sortAscending)
          ? getCompareSortOrder(a, b, sortAscending)
          : siteNameA.toLowerCase() < siteNameB.toLowerCase()
          ? -1
          : 1;
      },
      sortOrder: sortBy === "siteName" ? sortOrder : false,
    },
    {
      title: (
        <TableColumnTitle {...tableColumnTitleProps} dataIndex="postcode" />
      ),
      dataIndex: "postcode",
      key: "Postcode",
      sorter: (a, b) => {
        const sortAscending = sortOrder === ASCENDING_ORDER_KEY;

        return getCompareSortOrder(a, b, sortAscending)
          ? getCompareSortOrder(a, b, sortAscending)
          : a.postcode?.toLowerCase() < b.postcode?.toLowerCase()
          ? -1
          : 1;
      },
      sortOrder: sortBy === "postcode" ? sortOrder : false,
    },
    {
      title: (
        <TableColumnTitle {...tableColumnTitleProps} dataIndex="assetCount" />
      ),
      key: "Assets",
      dataIndex: "assetCount",
      sorter: (a, b) => {
        const sortAscending = sortOrder === ASCENDING_ORDER_KEY;

        return getCompareSortOrder(a, b, sortAscending)
          ? getCompareSortOrder(a, b, sortAscending)
          : (parseInt(a.assetCount) || 0) < (parseInt(b.assetCount) || 0)
          ? -1
          : 1;
      },
      sortOrder: sortBy === "assetCount" ? sortOrder : false,
    },
    {
      title: (
        <TableColumnTitle {...tableColumnTitleProps} dataIndex="updatedAt" />
      ),
      key: "Last Edited",
      dataIndex: "updatedAt",
      sorter: (a, b) => {
        const sortAscending = sortOrder === ASCENDING_ORDER_KEY;

        return getCompareSortOrder(a, b, sortAscending)
          ? getCompareSortOrder(a, b, sortAscending)
          : a.updatedAt < b.updatedAt
          ? -1
          : 1;
      },
      render: (_, item) => {
        return renderUpdatedAt(item);
      },
      sortOrder: sortBy === "updatedAt" ? sortOrder : false,
    },
    {
      title: "",
      fixed: "right",
      render: (facility) => {
        return (
          <div className="row-actions">
            <HiddenActionsInDropdown actions={actions(facility).slice(1)} />
          </div>
        );
      },
    },
  ]

  const actions = (facility) => {
    return [
      {
        dataSwitch: dataSwitch,
        render: () => (
          <FacilitySwitcher
            setFacilityCountWarning={() => setFacilityCountWarning(true)}
            facility={facility}
          />
        ),
      },
      {
        label: "Floors",
        key: "floors",
        link: `/facility/${facility.id}`,
        icon: <FloorIcon />,
        disabled: !(facilitiesToDownload || []).includes(facility.id),
      },
      {
        label: "Edit",
        key: "edit",
        onClick: () => {
          setSelectedFacility(facility);
          checkFacilityName(facility);
        },
        icon: <EditIcon />,
        disabled: !(facilitiesToDownload || []).includes(facility.id),
      },
      {
        label: "Complete Facility",
        key: "complete",
        icon: <CheckIcon />,
        onClick: () => {},
        disabled: true,
      },
    ];
  };

  function displayFacilities() {
    const render = (facility) => (
      <Descriptions
        column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
        size={"small"}
      >
        <Descriptions.Item label="Status">
          <Status status={facility.status} />
        </Descriptions.Item>
        <Descriptions.Item label="Postcode">
          {facility.postcode}
        </Descriptions.Item>
        <Descriptions.Item label="Spaces">
          {facility.spaceCount}
        </Descriptions.Item>
        <Descriptions.Item label="Assets">
          {facility.assetCount}
        </Descriptions.Item>
        <Descriptions.Item label="Last Edited">
          {renderUpdatedAt(facility)}
        </Descriptions.Item>
      </Descriptions>
    );

    if (!enhancedFacilities) {
      return <Spinner message="We are fetching and processing your data..." />;
    }

    return (
      <>
        <div className="content-header">
          <div className="table-header">
            <div style={{ display: "inline-flex" }}>
              <div className="listing-header-icon">{<FacilityIcon />}</div>
              <Typography.Title className="subtitle" level={3}>
                Facilities Active On Your Device{" "}
                <Typography.Text className="enabled-facilities-mention">
                  ({
                    (
                      facilitiesToDownload.filter((id) => facilityIds.includes(id)) || []
                    ).length
                  }{" "}
                  downloaded of {facilityIds.length} allocated)
                </Typography.Text>
              </Typography.Title>
            </div>
            <DataViewSwitch onChange={setDataSwitch} option={dataSwitch} />
          </div>
        </div>

        {dataSwitch === "table" && (
          <Table
            rowKey="id"
            scroll={{ x: "max-content" }}
            dataSource={enhancedFacilities}
            columns={FACILITY_COLUMNS}
            showSorterTooltip={false}
            pagination={{
              onChange: (curent, size) => {
                localStorage.setItem("InspectionsItemCountInView", size);
                setItemCountInView(size);
              },
              pageSize: itemCountInView,
              pageSizeOptions: [10, 20, 30, 50],
              showSizeChanger: true,
            }}
            rowClassName={(facility) => {
              return cx("facility-row", {
                disabled: !(facilitiesToDownload || []).includes(facility.id),
              });
            }}
          />
        )}

        {dataSwitch === "grid" && (
          <GridView
            records={enhancedFacilities}
            type={"facility"}
            actions={actions}
            render={render}
            displayCover
            dataSwitch={dataSwitch}
          />
        )}
        {selectedFacility && (
          <ItemDrawer
            onClose={() => {
              setSelectedFacility(false);
            }}
            visible={selectedFacility}
            item={selectedFacility}
            itemType="facility"
          />
        )}
      </>
    );
  }

  return (
    <div className="home-page">
      <Helmet title={"Home"} />
      {pageHeader}
      {enhancedFacilities && !isAcknowledged && (
        <Alert
          style={{ backgroundColor: "white" }}
          message="Managing your active facilities"
          description={
            <div>
              <br />
              <p>{`This listing below contains all of the facilities available for you to work on  the ${tenantData.name} account. Select only the facilities you need.`}</p>
              <p>
                You can return to this screen to change your facility allocation
                at any time as long as you have an active data connection.
                Facilities can only be un-allocated when all data has been
                synced with ELIAS.
              </p>
              <p>
                De-selecting a facility will remove the data from your device,
                it will not delete it from ELIAS.
                <strong>
                  {" "}
                  You can not change your facility allocation when working
                  offline.
                </strong>
              </p>
            </div>
          }
          type="info"
          showIcon
          closable
          onClose={() => localDbSet("facility-alert-closed", true)}
        />
      )}
      {!isOnline && !isOutboxEmpty && (
        <Alert
          message="Un-synced data for this facility"
          description={`The facility has un-synced data on your device and can not be set to inactive until the data has be successfully synced to ELIAS. This can only be done when online. The data will sync automatically when a data signal is available, the facility can then be set to inactive and the data will be removed from your device.`}
          type="warning"
          showIcon
          closable
        />
      )}
      {facilityCountWarning && (
        <Alert
          message="The maximum number of active facilities is 2"
          description={`You can have a maximum of 2 facilities active at any time. You will need to sync all data on an active facility and de-activate it in order to select another.`}
          type="warning"
          showIcon
          closable
          onClose={() => setFacilityCountWarning(false)}
        />
      )}
      {enhancedFacilities && (
        <FilterHeader
          filterTreeData={filterTreeData}
          filterOnChange={(change) => {
            onFiltersChange(change);
          }}
          filterOnCloseTag={(closeTag) => onFilterClose(closeTag)}
          filterValue={filters}
          sortByOptions={FACILITY_COLUMNS}
          {...tableColumnTitleProps}
        />
      )}
      {displayFacilities()}
    </div>
  );
}
