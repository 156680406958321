import React from "react";
import { Typography, Button } from "antd";
import { Link } from "react-router-dom";
import {
  FacilityIcon,
  AssetIcon,
  FloorIcon,
  TagIcon,
  FoldIcon,
  SpaceIcon,
} from "components/Icons";
import LocationSearch from "./LocationSearch/LocationSearch";
import { saveFavouriteSpaces } from "helpers/favouriteSpaceHelpers";

import "./AppSidebar.scss";
import { useEntityData } from "EntityDataContext/EntityDataContext";

export default function AppSidebarContent(props) {
  const {
    facility,
    floor,
    spaceId,
    space,
    setIsCollapsed,
    isCollapsed,
    isPortrait,
  } = props;

  const {favouriteSpacesState, assetCount } = useEntityData()

  const [favouriteSpaces, setFavouriteSpaces] = favouriteSpacesState;

  return (
    <>
      <div className="sidebar-item sidebar-header">
        <div className="item-header">
          <LocationSearch
            space={space}
            favouriteSpacesState={favouriteSpacesState}
          />
          {!isCollapsed && isPortrait && (
            <div
              className="fold-icon"
              onClick={() => setIsCollapsed((isCollapsed) => !isCollapsed)}
            >
              <FoldIcon />
            </div>
          )}
        </div>
      </div>
      <div className="sidebar-item item-assets">
        <div className="item-header">
          <div className="sidebar-icon">
            <AssetIcon />
          </div>
          <Link to="/assets">
            <Typography.Text className="item-text small-item-text">
              All Assets
              <Typography.Text className="item-superscript">
                {assetCount !== undefined ? assetCount : ""}
              </Typography.Text>
            </Typography.Text>
          </Link>
        </div>
      </div>
      {facility && (
        <div className="sidebar-item item-facility">
          <div className="item-header">
            <div className="sidebar-icon">
              <FacilityIcon />
            </div>
            <Typography.Text className="item-text small-item-text">
              {facility.name}
            </Typography.Text>
          </div>
          <div className="sub-item">
            <div className="sub-item-icon">
              <TagIcon />
            </div>
            <Typography.Text className="sub-item-text">
              Untagged assets
            </Typography.Text>
          </div>
          <div className="sub-item">
            <Button type="transparent" size="small">
              Facility journal
            </Button>
          </div>
        </div>
      )}
      {floor && (
        <div className="sidebar-item item-floor">
          <div className="item-header">
            <div className="sidebar-icon">
              <FloorIcon />
            </div>
            <Typography.Text className="item-text small-item-text">
              {floor.name}
            </Typography.Text>
          </div>
          <div className="sub-item">
            <Button type="transparent" size="small">
              Complete floor
            </Button>
          </div>
        </div>
      )}
      {spaceId && space && (
        <div className="sidebar-item item-space">
          <div className="item-header">
            <div className="sidebar-icon">
              <SpaceIcon />
            </div>
            <Typography.Text className="item-text small-item-text">
              {space.name}
            </Typography.Text>
          </div>
          <div className="sub-item">
            <Button
              type="transparent"
              size="small"
              onClick={() =>
                saveFavouriteSpaces(
                  setFavouriteSpaces,
                  favouriteSpaces,
                  space.id
                )
              }
            >
              {favouriteSpaces.includes(space.id) ? "Unfavorite" : "Favourite"}
            </Button>
          </div>
        </div>
      )}
    </>
  );
}
