import React from 'react'
import { Alert } from 'antd'
import { WarningIcon } from 'components/Icons'
import './FormEditAlert.scss'

const FormEditAlert = ({
  onClose
}) => (
  <Alert
    className="form-alert-box"
    closable
    message={(
      <div>
        <h2>Warning, data not saved</h2>
        <p>
          Cancel to close without changes or Save to keep progress before closing this panel
        </p>
      </div>
    )}
    showIcon
    icon={<WarningIcon className="alert-icon" />}
    type="warning"
    onClose={() => onClose()}
  />
)

export default FormEditAlert
