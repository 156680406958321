import styled from 'styled-components'
import { Modal } from 'antd'

const BUTTON_POSITIONS = {
  'top-left': {
    top: 'set',
    right: 'unset',
    bottom: 'unset',
    left: 'set'
  },
  'top-right': {
    top: 'set',
    right: 'set',
    bottom: 'unset',
    left: 'unset'
  },
  'bottom-right': {
    top: 'unset',
    right: 'set',
    bottom: 'set',
    left: 'unset'
  },
  'bottom-left': {
    top: 'unset',
    right: 'unset',
    bottom: 'set',
    left: 'set'
  }
}

const ContactHelpButtonStyled = styled.button`
  all: unset;
  box-sizing: border-box;
  position: absolute;
  top: ${ props => BUTTON_POSITIONS[props.position].top === 'set' ? `${ props.positionOffsetY }px` : 'unset' };
  bottom: ${ props => BUTTON_POSITIONS[props.position].bottom === 'set' ? `${ props.positionOffsetY }px` : 'unset' };
  left: ${ props => BUTTON_POSITIONS[props.position].left === 'set' ? `${ props.positionOffsetX }px` : 'unset' };
  right: ${ props => BUTTON_POSITIONS[props.position].right === 'set' ? `${ props.positionOffsetX }px` : 'unset' };
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: calc(100% - 2 * ${ props => props.positionOffsetX}px);
  margin: 0;
  padding: 0 16px;
  outline: 0;
  overflow: visible;
  font-family: inherit;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5715;
  background-color: #ff1945;
  border: 1px solid #ff1945;
  border-radius: 28px;
  color: #ffffff;
  touch-action: manipulation;
  user-select: none;
  white-space: nowrap;
  z-index: ${ props => props.buttonZindex };
  cursor: pointer;

  &:hover {
    color: #232323;
    background-color: #dadada;
    border-color: transparent;
  }

  @media (min-width: 768px) {
    width: unset;
    font-size: 14px;
  }

  .help-button__label {
    display: flex;
    align-items: center;

    svg {
      display: flex;
      flex-shrink: 0;
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }
  }
`

const ContactHelpModalStyled = styled(Modal)`
  border-radius: 20px;

  .ant-modal-content {
    border-radius: 16px !important;
  }

  .ant-modal-footer {
    display: flex;
    justify-content: center;
    border-top: unset;

    @media (min-width: 768px) {
      text-align: center;
    }
  }

  h1 {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 600;
  }

  .help-link-list {
    padding-left: 16px;
    list-style-type: none;

    li {
      display: flex;
      margin-bottom: 16px;
    }
  }

  .help-link-list__label {
    margin-left: 4px;
  }

  .help-modal-close-button {
    all: unset;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 100%;
    margin: 0;
    padding: 0 16px;
    outline: 0;
    overflow: visible;
    font-family: inherit;
    font-weight: 400;
    font-size: 16px;
    background-color: #ff1945;
    border: 1px solid #ff1945;
    border-radius: 28px;
    color: #ffffff;
    touch-action: manipulation;
    user-select: none;
    white-space: nowrap;
    cursor: pointer;

    &:hover {
      color: #232323;
      background-color: #dadada;
      border-color: transparent;
    }

    @media (min-width: 768px) {
      width: unset;
      font-size: 14px;
    }
  }
`
export default ContactHelpButtonStyled

export {
  ContactHelpModalStyled
}

