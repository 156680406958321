import { API } from "aws-amplify";
import {
  set as localDbSet,
  del as localDbDelete,
  get as localDbGet
} from "idb-keyval";
import { getDataURIFromBlob } from "helpers/generalHelpers";
import md5 from "md5";

import { makeImageLocalKey, throttledRefreshImages } from "./imageHelpers"

const DEVICE_IMAGE_ACTIONS = {
    ADD_DOWNLOAD: "addDownloaded",
    REM_DOWNLOAD: "removeDownloaded",
    ADD_PENDING: "addPendingDownload",
    REM_PENDING: "removePendingDownload",
    QUEUE: "addToQueue",
    PROCESS: "processQueue",
    ADD_EMPTY_FACILITY: "addEmptyFacility",
    BATCH: "handleCollection",
    HANDLE_REMOVE_FACILITY: "handleRemoveFacility",
    REMOVE_FACILITY_PENDING: "removeFacilityPending"
  }
    
  export const {
    ADD_DOWNLOAD,
    REM_DOWNLOAD,
    ADD_PENDING,
    REM_PENDING,
    QUEUE,
    PROCESS,
    ADD_EMPTY_FACILITY,
    BATCH,
    HANDLE_REMOVE_FACILITY,
    REMOVE_FACILITY_PENDING
  } = DEVICE_IMAGE_ACTIONS
  
  export const deviceImages = {
    isInitialised: false,
    isProcessing: false,
    downloaded: {},
    pendingDownload: {},
    pendingDownloadCount: 0,
    processingQueue: [],
    activeFacilities: {},
  
    initialiseImages: function(data) {
      this.isProcessing = true
      
      const addToDownloaded = {}
  
      data.forEach(k => {
        const keyArray = k.split("-")
        
        const type = keyArray.shift()
        const hash = keyArray.pop()
        const facilityId = keyArray.join("-")
  
        if (type === "downloadedImage") {
          const localKey = makeImageLocalKey({
            hash,
            facilityId,
          })
  
          if (!addToDownloaded[facilityId]) {
            this.enableFacility(facilityId)
            addToDownloaded[facilityId] = []
          }
  
          addToDownloaded[facilityId].push({facilityId, localKey})
        }
      })
      
      this.isInitialised = true
      this.isProcessing = false
  
      const facilities = Object.keys(addToDownloaded);
      
      if (facilities.length) {
        facilities.forEach(facilityId => {
          this.addToQueue(BATCH, {
            action: ADD_DOWNLOAD, 
            data: addToDownloaded[facilityId]
          })
        })
      } else {
        this.resumeProcessing()
      }
    },
  
    resumeProcessing: function() {
      if (this.processingQueue.length) {
        this.processQueue()
      }
    },
  
    processImage: async function(image, tryCount = 0, index, total) {
      try {
    
        const {data: imageResponse} = await API.post("rest", "/items/get-image", {
          body: image.imageData,
          response: true,
        });
        const blob = new Blob([new Uint8Array(imageResponse.Body.data)], {
          type: `${imageResponse.ContentType}+${imageResponse.ContentEncoding}`,
        });
    
        const imageDataUri = await getDataURIFromBlob(blob);
    
            await localDbSet(
              image.localKey,
              imageDataUri
            );
          
            await deviceImages.addDownloaded({
              facilityId: image.facilityId, 
              localKey: image.localKey
            });
  
            await deviceImages.removePendingDownload({
              facilityId: image.facilityId, 
              localKey: image.localKey
            })
      } catch(e) {
        // do not retry image if access denied, try it next time.
        if (e.response.data.code === "AccessDenied") {
          this.pendingDownloadCount--
          this.pendingDownload[image.facilityId][image.localKey].notExist = Date.now()
          return;
        }
        if (tryCount < 5) {
          await this.processImage(image, tryCount + 1, index, total);
        }
      }
    },
  
    downloadImages: async function(facilityId) {
      const images = Object.values(this.pendingDownload[facilityId])
      
      for (let i = 0; i < images.length; i++) {
        // abort if facility disabled
        if (
          !window.activeFacilities[images[i].facilityId] ||
          !window.isOnline
        ) {
          break;
        } 
        // continue if image is flagged as not exists

        if (images[i].notExist) {
          const compareDate = images[i].notExist + ( 1 * 60 * 1000 )

          console.error(images[i].notExist , ' - ', compareDate)
          if (Date.now() > compareDate) continue;
          if (Date.now() <= compareDate) this.pendingDownloadCount++
        }

        // waiting for each image to finish downloading is slower than other options,
        // but this way we can maintain more consistent performance
        await this.processImage(images[i], 0, i, images.length);
        
        // we want to let it "breathe" a bit between images, so we don't overload the device
        await new Promise((resolve) => setTimeout(resolve, 200));
      }
    },
  
    handlePendingImages: async function() {
      this.isProcessing = true
  
      const pendingFacilities = Object.keys(this.pendingDownload)
  
      for (let i = 0; i < pendingFacilities.length; i++) {
        const facilityId = pendingFacilities[i]
  
        if (window.activeFacilities[facilityId]) {
          await this.downloadImages(facilityId)
        } 
      }
  
      this.isProcessing = false

      this.resumeProcessing()
    },
  
    handleCollection: async function({action, data}) {
      for (let i = 0; i < data.length; i++) {
        this[action](data[i])
      }
    },
  
    addToQueue: async function(action, data) {
      this.processingQueue.push({action, data})
      this.processQueue()
    },
  
    processQueue: async function() {
      if (this.isProcessing) {
        return
      }
  
      this.isProcessing = true

      const {action, data} = this.processingQueue.shift()

      await this[action](data)
  
      this.isProcessing = false
      
      if (this.processingQueue.length) {
        this.processQueue()
        
        if (this.pendingDownloadCount > 0) {
          await this.handlePendingImages()
        } else {
          return
        }
      }
    },
  
    addDownloaded: async function({facilityId, localKey}) {
      this.downloaded[facilityId][localKey] = true
    },
  
    removeDownloaded: async function({facilityId, localKey}) {
      delete this.downloaded[facilityId][localKey]
    },
  
    addPendingDownload: async function({facilityId, localKey, imageData}) {
      this.pendingDownload[facilityId][localKey] = {
        imageData, 
        facilityId, 
        localKey
      }

      this.pendingDownloadCount++
    },
    
    removePendingDownload: async function({facilityId, localKey}) {
      delete this.pendingDownload[facilityId][localKey].facilityId
      delete this.pendingDownload[facilityId][localKey].localKey
      delete this.pendingDownload[facilityId][localKey].imageData.key
      delete this.pendingDownload[facilityId][localKey].imageData.bucket
      delete this.pendingDownload[facilityId][localKey].imageData
      delete this.pendingDownload[facilityId][localKey].notExist
      delete this.pendingDownload[facilityId][localKey]
      this.pendingDownloadCount--
    },
  
    addEmptyFacility: function(facilityId) {
      if (!this.downloaded[facilityId]) {
        this.downloaded[facilityId] = {}
      }
      if (!this.pendingDownload[facilityId]) {
        this.pendingDownload[facilityId] = {}
      }
    },
  
    disableFacility: function(facilityId) {
      if (window.activeFacilities[facilityId]) {
        delete window.activeFacilities[facilityId]
      }
    },
  
    enableFacility: function(facilityId) {
      this.addEmptyFacility(facilityId)
      window.activeFacilities[facilityId] = true
    },
  
    handleRemoveFacility: async function(facilityId) {
      const deletePromises = [];
    
      this.removeFacilityPending(facilityId)
    
      const downloadedImages = Object.keys(this.downloaded[facilityId] || {})
    
      for (let i = 0; i < downloadedImages.length; i++) {
          const key = downloadedImages[i]
    
          deletePromises.push(localDbDelete(key));
          this.removeDownloaded({
            facilityId, 
            localKey: key
          })
    
          if (deletePromises.length >= 100) {
            this.addToQueue(
                HANDLE_REMOVE_FACILITY, 
                facilityId
            )
            break;
          }
      }
    
      await Promise.all(deletePromises);
    },
  
    removeFacilityPending: function(facilityId) {
      const pendingDownloads = Object
        .keys(this.pendingDownload[facilityId] || {})
        .map(localKey => {
            return {localKey, facilityId}
        })

      if (pendingDownloads?.length) {
        this.addToQueue(BATCH, {
            action: REM_PENDING, 
            data: pendingDownloads
        })
      }
    },

    getRemoteImages: async function({ facilityId, images, setImages }) {
      async function fetchImageFromLocalStorage () {
        const fetchedImages = await Promise.all(
          images.map(async i => {
            const imageKey = md5(i.picture?.key)
            const imageLocalDbkey = `downloadedImage-${facilityId}-${imageKey}`
            const dataUri = await localDbGet(imageLocalDbkey)
            const newImageObj = { ...i, dataUri}
  
            return newImageObj
          })
        ).catch(err => console.error('Error retrieving images from datastore: ', err))
          
        return fetchedImages
      }

      // Requests images from remote store
      await throttledRefreshImages([{
        facilityId,
        images
      }])

      const newImages = await fetchImageFromLocalStorage()

      setImages(newImages)
    }
  }