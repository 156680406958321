export const units = [
  {
    label: 'Amp',
    value: 'Amp',
    type: 'electrical'
  },
  {
    label: 'Bar',
    value: 'Bar',
    type: 'pressure'
  },
  {
    label: 'cfm',
    value: 'cfm',
    type: 'volume'
  },
  {
    label: 'ft³/s',
    value: 'ft³/s',
    type: 'flow'
  },
  {
    label: 'ft',
    value: 'ft',
    type: 'length'
  },
  {
    label: 'ft²',
    value: 'ft²',
    type: 'area'
  },
  {
    label: 'ft³',
    value: 'ft³',
    type: 'volume'
  },
  {
    label: 'gal',
    value: 'gal',
    type: 'volume'
  },
  {
    label: 'gal (US)',
    value: 'gal (US)',
    type: 'volume'
  },
  {
    label: 'kg',
    value: 'kg',
    type: 'mass'
  },
  {
    label: 'kVA',
    value: 'kVA',
    type: 'electrical'
  },
  {
    label: 'kW',
    value: 'kW',
    type: 'electrical'
  },
  {
    label: 'kWh',
    value: 'kWh',
    type: 'energy'
  },
  {
    label: 'l',
    value: 'l',
    type: 'volume'
  },
  {
    label: 'lb',
    value: 'lb',
    type: 'mass'
  },
  {
    label: 'm',
    value: 'm',
    type: 'length'
  },
  {
    label: 'm²',
    value: 'm²',
    type: 'area'
  },
  {
    label: 'm³',
    value: 'm³',
    type: 'volume'
  },
  {
    label: 'm³/s',
    value: 'm³/s',
    type: 'flow'
  },
  {
    label: 'Mbh (1000 BTU)',
    value: 'Mbh (1000 BTU)',
    type: 'energy'
  },
  {
    label: 'Pa',
    value: 'Pa',
    type: 'pressure'
  },
  {
    label: 'psi',
    value: 'psi',
    type: 'pressure'
  },
  {
    label: 'Tonne',
    value: 'Tonne',
    type: 'mass'
  },
  {
    label: 'Tons',
    value: 'Tons',
    type: 'mass'
  },
  {
    label: 'Volts',
    value: 'Volts',
    type: 'electrical'
  },
  {
    label: 'Watts',
    value: 'Watts',
    type: 'electrical'
  },
  {
    label: 'yd',
    value: 'yd',
    type: 'length'
  }
]
