import React, { useState } from 'react'
import { Alert, Button, Form, Input, Select, Typography } from 'antd'
import { ChevronDownIcon } from 'components/Icons'
import { displayFormLabel } from 'helpers/formHelpers'
import facets from 'helpers/facets.json'
import './AssetValidationStatusUpdate.scss'

const AssetValidationStatusUpdate = ({
  form = {},
  initialValues = {},
  onNext = () => {},
  onSubmit = () => {},
  onClose = () => {}
}) => {
  const [ showNotes, setShowNotes ] = useState(false)
  const [ note, setNote ] = useState(initialValues?.['notes_reminder'])
  const [ assetValidated, setAssetValidated ] = useState(false)

  const isDisabled = false
  const validationStatusFacet = facets.find(f => f.facetId === 'uf46') || {}

  const { key = '', label = '', options = [], validation = {} } = validationStatusFacet
  const { mandatory: isMandatory } = validation

  const validationOptions = options.filter(o => o.code !== "New Asset")

  function handleValidationStatusUpdate(status) {
    form.setFieldsValue({ [`facets_${key}`]: status })

    setShowNotes(status === 'Unable to Locate')
    setAssetValidated(status !== 'Unable to Locate')
  }

  function handleNoteChange(val) {
    const initialValue = initialValues?.['notes_reminder'] || ''

    form.setFieldsValue({ 'notes_reminder': val })

    setNote(val)
    setAssetValidated(val !== initialValue && val !== '')
  }

  function handleOnSubmit() {
    if (showNotes) {
      form.setFieldsValue({ 'checkboxes_notes_reminder': true })

      const formFields = form.getFieldsValue(true)
      onSubmit(formFields)
    }

    onNext()
  }

  const infoBoxContent = () => (
    <div>
      This asset has not been validated. You need to select an appropriate Asset Validation Status;
      <ul className="validation-list">
        <li>
          <strong>Validated</strong> (100% confidence on asset match) - the asset exists and can be validated, 
          all available information will be captured. 
        </li>
        <li>
          <strong>Partial Validation</strong> (Not 100% confidence on asset match) - the asset exists; but can not 
          be uniquely identified on site, or another validation issue e.g. quantity.
        </li>
        <li>
          <strong>Unable to Locate</strong> - not able to validate this asset as it can not be found or identified.
        </li>
      </ul>
    </div>
  )

  const getAssetDetailsPreview = () => {
    const formValues = form.getFieldsValue(true)
    const {
      notes_description =  '-',
      facets_barcode = '-',
      'facets_existing-asset-number': facets_existingAssetNumber = '-',
      'facets_serial-number': facets_serialNumber = '-',
      facets_manufacturer = '-',
      facets_model = '-'
    } = formValues

    return (
      <ul className='asset-detail-list'>
        <li><Typography.Text>Local Asset Description: {notes_description}</Typography.Text></li>
        <li><Typography.Text>Asset Tag ID: {facets_barcode}</Typography.Text></li>
        <li><Typography.Text>Existing Asset Tag: {facets_existingAssetNumber}</Typography.Text></li>
        <li><Typography.Text>Serial Number: {facets_serialNumber}</Typography.Text></li>
        <li><Typography.Text>Manufacturer: {facets_manufacturer}</Typography.Text></li>
        <li><Typography.Text>Model: {facets_model}</Typography.Text></li>
      </ul>
    )
  }

  return (
    <>
      <Alert
        className="validation-message"
        style={{ backgroundColor: "white" }}
        message="Validate Asset Status"
        description={infoBoxContent()}
        type="info"
        showIcon
      />
      {getAssetDetailsPreview()}
      {displayFormLabel({ label, isDisabled, isMandatory })}
      <Form.Item
        name={key}
        initialValue=''
      >
        <Select
          suffixIcon={<ChevronDownIcon />}
          defaultActiveFirstOption={false}
          placeholder={label}
          onChange={val => handleValidationStatusUpdate(val)}
        >
          {validationOptions?.map(option => (
            <Select.Option key={option.code} value={option.code}>
              {option.label}
            </Select.Option>
          ))}
        </Select>

        {showNotes && (
          <div className='notes-field'>
            {displayFormLabel({
              label: 'Notes',
              isDisabled,
              isMandatory: true 
            })}
            <Input.TextArea
              value={note}
              onChange={e => handleNoteChange(e.target.value)}
            />
          </div>
        )}

        <div className="form-buttons">
          <Button
            type="transparent"
            onClick={() => {
              onClose()
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={!assetValidated}
            type="primary"
            onClick={handleOnSubmit}
          >
            <span>{showNotes ? 'Update' : 'Next'}</span>
          </Button>
        </div>
      </Form.Item>
    </>
  )
}

export default AssetValidationStatusUpdate
