import { Auth } from "aws-amplify";
import { useState } from "react";
import { keys as localDbKeys, set as localDbSet } from "idb-keyval";
import { Tooltip } from "antd";
import { isServerOnline } from "helpers/apiHelpers";
import { LOGIN_URL } from "config";
import { APP_ENV } from "config";

function twoDigits(num) {
  return ("0" + num).slice(-2);
}

export function formatDateTime(a, includeTime = false) {
  const b = new Date(a);
  const date = `${twoDigits(b.getDate())}/${twoDigits(
    b.getMonth() + 1
  )}/${b.getFullYear()}`;
  const time = `${twoDigits(b.getHours())}:${twoDigits(b.getMinutes())}`;
  return `${date}${includeTime ? ` ${time}` : ``}`;
}

export function useForceUpdate() {
  const [value, setValue] = useState(0); //eslint-disable-line no-unused-vars
  return () => setValue((value) => ++value); // update the state to force render
}

export async function getDataURIFromBlob(blob) {
  const dataUri = await new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      function () {
        resolve(reader.result);
      },
      false
    );

    if (blob) {
      reader.readAsDataURL(blob);
    } else {
      reject();
    }
  });
  return dataUri;
}

export function getBlobFromDataURI(dataURI) {
  var binary = atob(dataURI.split(",")[1]);
  var array = [];
  for (var i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], { type: "image/jpeg" });
}

export async function getLocalDbKeysSet() {
  const allLocalDbKeys = await localDbKeys();
  const keySet = new Set();
  for (let i = 0; i < allLocalDbKeys.length; i++) {
    keySet.add(allLocalDbKeys[i]);
  }
  return keySet;
}

export function buildURL({ facility, floor, space }) {
  let url = "/";
  if (facility) {
    url = `/facility/${facility.id}/`;
  }
  if (floor) {
    url = `/floor/${floor.id}/`;
  }
  if (space) {
    url = `/space/${space.id}/`;
  }
  return url.replace(/\s+/g, "-");
}

export async function logOut(isOnline, setReferer) {
  let redirectURL = null;

  await localDbSet("facility-alert-closed", false);

  if (isOnline) {
    try {
      isOnline = await isServerOnline();
      redirectURL = `${LOGIN_URL[APP_ENV]}/?signout=true`
      if (setReferer) {
        redirectURL += `&referer=inspections`
      }

    } catch (e) {
      isOnline = false;
    }
  }

  if (!isOnline) {
    redirectURL = null;
  } else {
    await Auth.signOut();
  }

  if (redirectURL) {
    console.info('Routing to...', redirectURL)
    window.location.href = redirectURL;
  }
}

export const setUserName = ({ familyName, givenName } = {}) =>
  familyName && givenName ? `- ${givenName.charAt(0)} ${familyName}` : "";

const handleUserName = (item) => {
  if (item.updatedUser) return setUserName(item.updatedUser);
  if (item.createdUser) return setUserName(item.createdUser);
  if (item.createdBy) return `- ${item.createdBy}`;
  return "";
};

export const cleanDate = {
  created: (user) => ['date', 'createdAt'].reduce((acc, fieldName) => acc !== undefined ? acc : user?.[fieldName], undefined),
  updated: (user) => ['date', 'updatedAt'].reduce((acc, fieldName) => acc !== undefined ? acc : user?.[fieldName], undefined)
}

function getLastUpdatedDate(item) {
  if (item.updatedUser && cleanDate.updated(item.updatedUser)) {
    return formatDateTime(cleanDate.updated(item.updatedUser), true)
  }

  if (item.createdUser && cleanDate.created(item.createdUser)) {
    return formatDateTime(cleanDate.created(item.createdUser), true)
  }

  if (item.updatedAt) { return formatDateTime(item.updatedAt, true) }

  if (item.createdAt) { return formatDateTime(item.createdAt, true); }
}

export const renderUpdatedAt = (item) => (
  <>
    {getLastUpdatedDate(item)}
    {
      <Tooltip
        placement={"top"}
        title={
          (item.updatedUser &&
            `${item.updatedUser?.givenName} ${item.updatedUser?.familyName} ${item.updatedUser?.email}`) ||
          (item.createdUser &&
            `${item.createdUser?.givenName} ${item.createdUser?.familyName} ${item.createdUser?.email}`)
        }
      >
        &nbsp;
        {handleUserName(item)}
      </Tooltip>
    }
  </>
);
