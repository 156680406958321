import React from 'react'
import HelpLinkStyled from './HelpLinkStyled'

const HelpLink = ({
  className = "help-link",
  icon = null,
  openInNewTab = true,
  text,
  url,
}) => (
  <HelpLinkStyled
    className={className}
    href={url}
    target={openInNewTab ? "_blank" : "_self"}
    rel="noopener noreferrer"
  >
    {icon}
    <span>{text}</span>
  </HelpLinkStyled>
)

export default HelpLink
