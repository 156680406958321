import { Auth } from "aws-amplify";
import awsExports from "aws-exports";
import axios from "axios";
import { notification, Typography } from "antd";
import { logOut } from "helpers/generalHelpers";

export async function callRest({
  method,
  route,
  body,
  message = "",
  isJson = true,
  isBlob = false,
}) {
  let session = await getCurrentSession();

  return await captureError({
    displayError: true,
    callback: async () => {
      let response = null;
      try {
        let headers = {
          Authorization: `${session.idToken.jwtToken}`,
        };

        if (isJson) {
          headers["Content-Type"] = "application/json";
        }
        const axiosParams = {
          url: `${getRestEndpoint()}${route}`,
          method,
          data: body,
          headers,
        };
        if (isBlob) {
          axiosParams.responseType = "blob";
        }
        const rawResponse = await axios(axiosParams);
        response = rawResponse.data;

        if (response && response.error) {
          throw new Error(response.error);
        }

        return response;
      } catch (e) {
        throw e;
      }
    },
    message,
  });
}

export async function getCurrentSession() {
  let session;
  let tryCount = 0;

  while (!session && tryCount < 5) {
    tryCount++;
    
    try {
      session = await Auth.currentSession();
      if (session && tryCount > 1) {
        console.log("found current user after retry");
      }
    } catch (e) {
      console.log("looking for current user - retrying");
      await new Promise((resolve) => setTimeout(resolve, 500));
    }
  }

  if (!session) {
    // EMW-1869 - Surveyors are being logged in by previously stored browser cookies which appears to cause 
    // amplify from failing to get the current session if a cookie has now become invalid

    logOut(true);
  }

  return session;
}

export async function getDataURL(){
  const session = await getCurrentSession()

  if (session) {
    const credentials = btoa(JSON.stringify({
      idToken: session.idToken.jwtToken,
      userIdAuth: session.idToken.payload.sub,
      refreshToken: session.refreshToken.token,
      tenantId: 'blank'
    }))

    return `?data=${ credentials }`
  }
}

export function getRestEndpoint() {
  const restApiElement = awsExports.aws_cloud_logic_custom.find(
    (x) => x.name === "rest"
  );
  return restApiElement.endpoint;
}

async function captureError({ callback, message, displayError }) {
  try {
    const result = await callback();
    return result;
  } catch (e) {
    let sentryMessage = message;

    if (displayError) {
      displayErrorMessage(
        e,
        message || "There has been an error",
        sentryMessage
      );
    }
    throw e;
  }
}

export function displayErrorMessage(e, message, reason) {
  let isNetworkError = false;
  if (e.message) {
    reason = e.message;
    // reason = e.errors.map((error, i) => <Typography.Paragraph key={i}>{error.message}</Typography.Paragraph>);
  } else if (e.errors && e.errors[0] && e.errors[0].message) {
    reason = e.errors[0].message;
  } else {
    reason = "GraphQL API error";
  }

  isNetworkError = reason === "Network Error";

  notification.error({
    message: (
      <Typography.Text>
        {message}.
        <br />
        <b>Reason:</b>{" "}
        {isNetworkError ? (
          "Network error (You are probably offline)"
        ) : (
          <>
            <br />
            {reason}
          </>
        )}
        <br />
        {/* {isNetworkError ? null : (
          <b>Our team has been notified of the error, and we will fix it as soon as possible.</b>
        )} */}
      </Typography.Text>
    ),
    duration: 0,
  });
}

export function isServerOnline() {
  let url =
    "https://inspections-public-content.s3.eu-west-2.amazonaws.com/online_test.png";

  return new Promise((resolve, reject) => {
    let image = new Image();
    image.src = url + "?" + Date.now();

    image.onload = resolve;
    image.onerror = reject;
    // () => {
    //   console.warn("server is offline!"), setTimeout(isServerOnline, 2000, url);
    // };
  });
}
