import React from "react";
import ReactDOM from "react-dom";
import { keys as localDbKeys, del as localDbDelete } from "idb-keyval";

import App from "./App/App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { Amplify, Auth } from "aws-amplify";
import { CookieStorage } from '@aws-amplify/auth'
import awsExports from "./aws-exports";
import Cookie from "js-cookie";
import { getCurrentSession, isServerOnline } from "helpers/apiHelpers";

import "antd/dist/antd.css";
import "styles/generic.scss";
import "styles/table.scss";
import EntityDataProvider from "EntityDataContext/EntityDataContext";
import { logOut } from "helpers/generalHelpers";

const getSharedHostname = () => {
  const hostname = window.location.hostname

  if (hostname === 'localhost') {
    return hostname
  }

  return hostname.substring(hostname.indexOf('.'))
}

Amplify.configure({
  ...awsExports,
  API: {
    graphql_headers: async () => ({
      Authorization: (await Amplify.Auth.currentSession())
        .getIdToken()
        .getJwtToken(),
    }),
  },
  Auth: {
    cookieStorage: new CookieStorage({
      domain: getSharedHostname(),
      sameSite: 'lax'
    })
  }
});

async function clearOldErrorKeys() {
  //clear any error logs older than 7 days
  const allLocalDbKeys = await localDbKeys();
  const errorKeys =  allLocalDbKeys.filter((key) => key.startsWith("error"));
  errorKeys.forEach((key) => {
    try {
      if (new Date(key.substring(6,30)) < new Date(new Date().setDate(new Date().getDate()-7))) {
        localDbDelete(key);
        console.log('Expired error removed', key)
      }
    }
    catch {
      localDbDelete(key);
    }
  })
}

function setCognitoTokens({
  userIdAuth,
  cognitoId,
  refreshToken,
  idToken
}){
  Cookie.set(
    `CognitoIdentityServiceProvider.${ cognitoId }.LastAuthUser`,
    userIdAuth,
    {
      expires: 999999,
      domain: getSharedHostname()
    }
  )
  Cookie.set(
    `CognitoIdentityServiceProvider.${ cognitoId }.${ userIdAuth }.refreshToken`,
    refreshToken,
    {
      expires: 999999,
      domain: getSharedHostname()
    }
  )
  Cookie.set(
    `CognitoIdentityServiceProvider.${ cognitoId }.${ userIdAuth }.idToken`,
    idToken,
    {
      expires: 999999,
      domain: getSharedHostname()
    }
  )
}

function decodeURL() {
  const searchParams = new URLSearchParams(window.location.search);
  const data = searchParams.get('data');
  return JSON.parse(atob(data))
}

async function checkUrl() {
  const {
    idToken, refreshToken, userIdAuth
  } = decodeURL() || {}

  if(!idToken || !refreshToken || !userIdAuth) {
    throw Error('Missing user authentication data. Returning to login.')
  }

  setCognitoTokens({
    cognitoId: awsExports.aws_user_pools_web_client_id,
    userIdAuth,
    refreshToken,
    idToken
  })
}

function loadTenant() {
  try {
    const { tenantId } = decodeURL()
    const tenantIdCookie = Cookie.get('tenantId')

  if (tenantId) {
    Cookie.set('tenantId', tenantId, {
      domain: getSharedHostname(),
      expires: 999999
    })
    
    window.tenantId = tenantId
  } else if (tenantIdCookie) {
    window.tenantId = tenantIdCookie
  }

  console.info({tenantId, tenantIdCookie}, window.tenantId)
  } catch (error) {
    console.error('Decode URL failed.')
  }
  
}

async function renderApp() {
  loadTenant()
  
  window.history.replaceState(null, '', window.location.pathname)
  
  let isOffline = false;
  try {
    let isOnline = await isServerOnline();
    isOffline = !Boolean(isOnline)
  } catch (e) {
    isOffline = true;
    try {
      await getCurrentSession();
      alert("Offline mode.");
    } catch (e) {
      alert("Application is offline, but there is no user session.");
    }
  }

  ReactDOM.render(
    <React.StrictMode>
      <EntityDataProvider online={!isOffline}>
        <App />
      </EntityDataProvider>
    </React.StrictMode>,
    document.getElementById("root")
  );

  // If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
}

async function run() {
  clearOldErrorKeys()

  try {
    await Auth.currentSession()
    console.info('session from cookie')
    await renderApp()
  } catch (authError) {
    console.error(authError)
    try {
      await checkUrl() 
      await renderApp()
    } catch (error) {
      console.error('URL decoding error: ', error)
      await logOut(true, true)
    }
  }
}

run();


