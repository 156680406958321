import { Input, Button, Tooltip } from "antd";
import { useState } from "react";
import { ScanOutlined } from "@ant-design/icons";
import BarcodeScannerContextProvider from "components/BarcodeScannerContextProvider";
import BarcodeScanner from "components/BarcodeScanner";

const removePrefix = (prefix, text) => {
  if (!prefix) return text;
  if (text.startsWith(prefix)) {
    return text.slice(prefix.length);
  }
  return text;
};

const BarcodeInput = ({
  prefix,
  setFocus,
  onBlurTimeout,
  inputKey,
  form,
  forceUpdate,
}) => {
  const [scannerOpen, setScannerOpen] = useState(false);
  return (
    <>
      <Input.Group compact style={{ display: "flex" }}>
        <Input
          onFocus={setFocus}
          onBlur={() => onBlurTimeout()}
          style={{ flexGrow: 1 }}
          defaultValue={form.getFieldValue(inputKey)}
          value={form.getFieldValue(inputKey)}
          onChange={(suffix) => {
            form.setFieldsValue({ [inputKey]: suffix.target.value });

            if (suffix.target.value === "")
              form.setFieldsValue({ [inputKey]: undefined });

            form.validateFields([inputKey]);
            forceUpdate();
          }}
          dataId="barcode-input-field"
        />
        <Tooltip
          key="ScanToolTip"
          title={"Scan the bar/QR code using your devices camera"}
        >
          <Button
            icon={<ScanOutlined />}
            onClick={() => setScannerOpen(true)}
            disabled={scannerOpen}
          />
        </Tooltip>
        {/* increment barcode: */}
        {/* <Button icon={<PlusSquareOutlined />} /> */}
      </Input.Group>
      <BarcodeScannerContextProvider>
        <BarcodeScanner
          open={scannerOpen}
          setOpen={setScannerOpen}
          setBarcode={(txt) =>
            form.setFieldsValue({ [inputKey]: removePrefix(prefix, txt) })
          }
        />
      </BarcodeScannerContextProvider>
    </>
  );
};

export { BarcodeInput as default };
