import styled from 'styled-components'

const HelpLinkStyled = styled.a`
  display: inline-flex;
  align-items: center;
  color: #ff1945;
  text-decoration: underline;

  svg {
    display: flex;
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    color: #ff1945;
  }

  &:link,
  &:visited {
    color: #ff1945;
    font-weight: normal;
    text-decoration: underline;
  }

  &:hover {
    text-decoration: underline;
    font-weight: normal;
    color: #ff1945;
    cursor: pointer;

    svg {
      color: #ff1945;
      transition: color 0.3s;
    }
  }
`

export default HelpLinkStyled
