import React, { useState, useEffect, useMemo, useRef } from "react";
import { Drawer, Button, Form, Typography, Menu, Checkbox } from "antd";
import AssetValidationStatusUpdate from "components/AssetValidationStatusUpdate/AssetValidationStatusUpdate";
import { set as localDbSet } from "idb-keyval";
import moment from "moment";
import { DataStore } from "@reams/elias-store";
import { Asset, Space, Floor, Facility } from "models";
import cx from "classnames";
import { v4 as uuidv4 } from "uuid";
import { isEqual } from "lodash";

import {
  useForceUpdate,
  formatDateTime,
  setUserName,
  cleanDate,
} from "helpers/generalHelpers";
import {
  setLocalImagesToState,
  getImagesForAPI,
} from "helpers/drawerHelpers";
import {
  customFacetList,
  spaceDefinition,
  floorDefinition,
  facilityDefinition,
} from "helpers/drawerData";
import ButtonPair from "components/ButtonPair/ButtonPair";
import AssetTypeDrawer from "./AssetTypeDrawer/AssetTypeDrawer";
import SearchableTree from "components/SearchableTree/SearchableTree";
import FormEditAlert from "components/FormEditAlert/FormEditAlert";
import {
  DisplayFormFields,
  displayErrors,
  getNestedFieldFromForm,
  getInitialValues as helperGetInitialValues,
  stripAsset as helperStripCopy,
  makeEoL,
} from "helpers/formHelpers";
import {
  getAssetDefinition,
  getAntTreeFromGlobalHierarchy,
} from "helpers/hierarchyHelpers";
import facetDefinitions from "helpers/facets.json";

import "styles/drawer.scss";
import "./ItemDrawer.scss";
import { FullList, ShortList } from "./AssetTypeDrawer/AssetTypeMessaging";
import HelpIcon from "components/HelpIcon/HelpIcon";
import { useEntityData } from "EntityDataContext/EntityDataContext";

const models = {
  asset: Asset,
  space: Space,
  floor: Floor,
  facility: Facility,
};

export default function ItemDrawer(props) {
  const ASSET_FAST_MOVERS_FEATURE_FLAG = true;

  let {
    item,
    onClose,
    visible,
    itemType,
    parents = {},
    copy,
    isFabric = false,
    isFabricSection = false,
  } = props;

  let isNewItem = false

  const { user, tenantData } = useEntityData()

  const hierarchy = window.hierarchy;

  const [form] = Form.useForm();

  const forceUpdate = useForceUpdate();

  const [isLoading, setIsLoading] = useState(false);
  const [saveThenCopy, setSaveThenCopy] = useState(false);
  const [fabricSelectorId, setFabricSelectorId] = useState(
    item?.facets?.selectorId
  );
  const [isAssetTypeDrawerVisible, setIsAssetTypeDrawerVisible] =
    useState(false);
  const [assetDefinition, setAssetDefinition] = useState();
  const [errors, setErrors] = useState();
  const [areErrorsVisible, setAreErrorsVisible] = useState(false);
  const [areImagesLoaded, setAreImagesLoaded] = useState(false);
  const [assetTypeTreeData, setAssetTypeTreeData] = useState();
  const [assetType8020Checked, setAssetType8020Checked] = useState(true);
  const [images, setImages] = useState([]);
  const [isDirty, setIsDirty] = useState(false);
  const [defaultValues, setDefaultValues] = useState({});
  const [requiresValidation, setRequiresValidation] = useState(false);

  const mountedRef = useRef(true);

  useEffect(() => {
    if (itemType !== "asset") {
      return;
    }

    if (assetType8020Checked && ASSET_FAST_MOVERS_FEATURE_FLAG) {
      const treeData = getAntTreeFromGlobalHierarchy(hierarchy, true);
      setAssetTypeTreeData(treeData);
      return;
    }
    const treeData = getAntTreeFromGlobalHierarchy(hierarchy);
    setAssetTypeTreeData(treeData);
  }, [itemType, assetType8020Checked]);

  useEffect(() => {
    if (isDirty) {
      scrollToBottom();
    }
  }, [ isDirty])

  function scrollToBottom() {
    const targetEle = document.querySelector(".form-alert-box");

    if (targetEle) {
      const formDrawerEle = targetEle.closest(".ant-drawer-body");

      if (formDrawerEle) {
        formDrawerEle.scrollTo({
          top: formDrawerEle.scrollHeight,
          left: 0,
          behavior: "smooth",
        });
      }
    }
  }

  function AssetType8020() {
    return (
      <div
        style={{
          paddingTop: 10,
          paddingBottom: 5,
          borderTop: "1px solid lightgray",
          display: "flex",
        }}
      >
        <div style={{ flexGrow: 1 }}>
          <Checkbox
            defaultChecked={assetType8020Checked}
            value={assetType8020Checked}
            onChange={(e) => setAssetType8020Checked(e.target.checked)}
          >
            Show only the most commonly used asset types
          </Checkbox>
        </div>
        <div
          style={{
            paddingRight: 10,
          }}
        >
          <HelpIcon
            text={
              "Show only the most commonly used asset types when selected. The full asset type list will be displayed when unselected."
            }
          />
        </div>
      </div>
    );
  }

  // without forcing re-render, the textboxes that open only when the checkbox is checked do not initially show up

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, [mountedRef]);

  useEffect(() => {
    setDefaultValues(getInitialValues());

    forceUpdate();
  }, []);

  const itemDefinitions = {
    asset: assetDefinition,
    space: spaceDefinition,
    floor: floorDefinition,
    facility: facilityDefinition,
  };

  const model = models[itemType];
  const itemDefinition = itemDefinitions[itemType];

  const strip = useMemo(() => {
    if (copy) {
      return helperStripCopy(copy);
    }
  }, [copy]);

  useEffect(() => {
    if (
      (!item && !strip) ||
      (itemType !== "facility" && !parents?.facility) ||
      areImagesLoaded
    ) {
      return;
    }
    if (strip) {
      const isVirtual = strip?.assetType?.virtual || false;
      const originatingFacility = strip?.facilityId;
      const targetedFacility = parents?.facility?.id;

      if (!(isVirtual && (originatingFacility !== targetedFacility))) {
        setLocalImagesToState({
          setImages,
          item: strip,
          facilityId: strip.facilityId,
          forceUpdate,
          setAreImagesLoaded,
          mounted: mountedRef.current,
          strip: true
        });
      }
    }

    if (itemType !== "floor" && !strip) {
      setLocalImagesToState({
        setImages,
        item: item || strip,
        facilityId:
          itemType === "facility"
            ? item.id
            : item?.facilityId || strip?.facilityId,
        forceUpdate,
        setAreImagesLoaded,
        mounted: mountedRef.current,
      });
    }
  }, [item, parents, areImagesLoaded, strip]);

  useEffect(() => {
    if (itemType !== "asset" || !hierarchy || assetDefinition) {
      return;
    }

    const assetType = form.getFieldValue("assetType");

    if (!assetType) {
      return;
    }

    const newAssetDefinition = getAssetDefinition(assetType.eliasID, hierarchy);
    const newAssetFacets = newAssetDefinition?.facets;
   
    if (!isNewItem && newAssetFacets.includes('uf46')) {
      const validationDefinition = facetDefinitions.find(f => f.facetId === 'uf46') || {};
      const validationOptions = validationDefinition?.options?.map(o => o.code) || [];
      const currentValidationValue = form.getFieldValue(`facets_${validationDefinition.key}`);

      setRequiresValidation(!validationOptions.includes(currentValidationValue));
    }

    setAssetDefinition(newAssetDefinition);
  }, [form.getFieldValue("assetType"), hierarchy, assetDefinition]);

  function getInitialValues() {
    let initialValues = {
      ...helperGetInitialValues(item || strip),
    };

    if (itemType === "asset") {
      if (item || strip) {
        const assetDefinition = getAssetDefinition(
          (item?.assetType || strip?.assetType).eliasID,
          hierarchy
          );
          initialValues.assetType = mapAssetType(assetDefinition);
        }
      initialValues.facets_quantity = initialValues.facets_quantity || 1;

      if (isFabricSection) {
        initialValues.assetType = {
          fabricElement: true,
          fabricParent: true,
          description: hierarchy[0].type,
          tree: hierarchy[0].tree,
          eliasID: hierarchy[0].eliasID,
          legacyId: hierarchy[0].legacyId,
          nrm: hierarchy[0].nrm,
          sfgCode: hierarchy[0].sfgCode,
        };
      }
    } else {
      initialValues = {
        ...initialValues,
        ...item,
        ...strip,
      };
      if (!item) {
        customFacetList
          .filter((facet) => itemDefinition.facets?.includes(facet.facetId))
          .forEach((facet) => {
            if (facet.defaultValue) {
              initialValues[facet.key] = facet.defaultValue;
            }
          });
      }
    }
    
    return initialValues;
  }

  function onFinishFailed({ errorFields }) {
    setErrors(errorFields);
    setAreErrorsVisible(true);
  }

  function mapAssetType(assetType) {
    return {
      description: assetType?.type,
      tree: assetType?.tree,
      eliasID: assetType?.eliasID,
      legacyId: assetType?.legacyId,
      lifecycle: assetType?.lifecycle,
      replacementCost: assetType?.replacementCost,
      virtual: assetType?.virtual,
      nrm: assetType?.nrm,
      sfgCode: assetType?.sfgCode,
      knownAs: assetType?.knownAs,
    };
  }

  function dateTime() {
    const date = new Date();
    return date.toISOString();
  }

  async function onSubmit(formFields) {
    if (isLoading) return;

    setIsLoading(true);

    const userEmail = user.idToken.payload.email;

    const facets = getNestedFieldFromForm({ formFields, fieldName: "facets" });
    const notes = getNestedFieldFromForm({ formFields, fieldName: "notes" });
    const byPasses = getNestedFieldFromForm({
      formFields,
      fieldName: "byPass",
    });

    if (facets) {
      if (form.getFieldValue("facets_conditionTags")) {
        facets["conditionTags"] = form.getFieldValue("facets_conditionTags");
      }

      if (facets["install-date"]) {
        facets["install-date"] = moment(facets["install-date"])
          .set({ month: 0, date: 1 })
          .format("YYYY-MM-DD");
      }

      if (form.getFieldValue("barcodePrefix") && facets.barcode) {
        facets.barcode = `${form.getFieldValue("barcodePrefix")}${
          facets.barcode
        }`;
      }

      if (isFabric) {
        if (isFabricSection) {
          facets.selectorId = hierarchy[0].selectorId;
        } else {
          facets.selectorId = fabricSelectorId;
        }
      }
    }

    if (notes) {
      if (itemType === "asset") {
        notes.image = {
          description: form.getFieldValue("restricted_image_reason"),
          status: "Image restricted",
        };
      }
      for (let key in byPasses) {
        notes[key.replace("byPass_", "")] = {
          status: byPasses[key],
        };
      }
    }

    const itemId = uuidv4();

    let imagesForAPI;
  
    if (itemType !== "floor") {
      imagesForAPI = await getImagesForAPI({
        facility: itemType === "facility" ? item : parents.facility,
        images,
        itemId,
        tenantData,
      });

    }

    let copyItem;
    if (!item) {
      const newItemParams = {
        id: itemId,
        tenantId: parents.facility.tenantId,
        facets: JSON.stringify(facets),
        notes: JSON.stringify(notes),
      };

      for (let fieldName in formFields) {
        if (
          !fieldName.includes("notes_") &&
          !fieldName.includes("facets_") &&
          !fieldName.includes("checkboxes_")
        ) {
          newItemParams[fieldName] = formFields[fieldName];
        }
      }
      switch (itemType) {
        case "asset":
          const assetType = form.getFieldValue("assetType");
          newItemParams.lifecycle = assetType.lifecycle;
          delete assetType.lifecycle;
          delete assetType.replacementCost;
          delete assetType.knownAs;
          if (newItemParams.lifecycle) {
            newItemParams.eol = makeEoL(
              form.getFieldValue("facets_install-date"),
              newItemParams.lifecycle
            );
          }
          newItemParams.images = imagesForAPI;
          newItemParams.assetType = assetType;
          if (isFabric) {
            newItemParams.assetType.fabricElement = true;
          }
          newItemParams.spaceId = parents.space?.id || "nothing";
          newItemParams.floorId = parents.floor?.id || "nothing";
          newItemParams.facilityId = parents.facility.id;
          newItemParams.facilityCode = parents.facility.code;
          newItemParams.siteId = parents.facility.siteId;
          newItemParams.regionId = parents.facility.regionId;
          break;
        case "space":
          newItemParams.active = true;
          newItemParams.images = imagesForAPI;
          newItemParams.floorId = parents.floor.id;
          newItemParams.facilityId = parents.facility.id;
          newItemParams.facilityCode = parents.facility.code;
          newItemParams.siteId = parents.facility.siteId;
          newItemParams.regionId = parents.facility.regionId;
          break;
        case "floor":
          newItemParams.images = imagesForAPI;
          newItemParams.active = true;
          newItemParams.facilityId = parents.facility.id;
          newItemParams.siteId = parents.facility.siteId;
          newItemParams.regionId = parents.facility.regionId;
          newItemParams.facilityCode = parents.facility.code;
          break;
        default:
          break;
      }
      copyItem = {
        ...newItemParams,
      };

      if (newItemParams?.facets) {
        copyItem.facets = JSON.parse(newItemParams.facets);
      }

      if (newItemParams?.notes) {
        copyItem.notes = JSON.parse(newItemParams?.notes);
      }

      newItemParams.createdBy = userEmail;

      newItemParams.createdUser = {
        givenName: user.idToken.payload.given_name,
        familyName: user.idToken.payload.family_name,
        email: userEmail,
        createdAt: dateTime(),
      };

      await DataStore.save(new model(newItemParams))
        .catch((err) => {
          setIsLoading(false);
          console.log({ DataStore_Save_ErrorMessage: err })

          localDbSet(
          `error-${new Date().toISOString()}-DataStore_Save_ErrorMessage`,
          JSON.stringify(err)
        )
          return;
        });
  } else {
      const original = await DataStore.query(model, item.id);
      
      copyItem = original;
      await DataStore.save(
        model.copyOf(original, (updated) => {
          if (imagesForAPI) {
            updated.images = imagesForAPI;
          }
          if (facets) {
            updated.facets = JSON.stringify({
              ...(original.facets || {}),
              ...facets,
            });
          }

          if (notes) {
            updated.notes = JSON.stringify({
              ...(original.notes || {}),
              ...notes,
            });
          }

          if (form.getFieldValue("assetType")) {
            const assetType = form.getFieldValue("assetType");
            updated.lifecycle = assetType.lifecycle;
            delete assetType.lifecycle;
            delete assetType.replacementCost;
            delete assetType.knownAs;
            if (updated.lifecycle) {
              updated.eol = makeEoL(facets["install-date"], updated.lifecycle);
            }
            updated.assetType = form.getFieldValue("assetType");
            if (isFabric) {
              updated.assetType.fabricElement = true;
            }
          }

          for (let fieldName in formFields) {
            if (
              fieldName &&
              fieldName.length !== 0 &&
              !fieldName.includes("notes_") &&
              !fieldName.includes("facets_") &&
              !fieldName.includes("checkboxes_")
            ) {
              updated[fieldName] = formFields[fieldName];
            }
          }
          updated.updatedUser = {
            givenName: user.idToken.payload.given_name,
            familyName: user.idToken.payload.family_name,
            email: userEmail,
            date: dateTime(),
          };
        })
      )
      .catch((err) => {
        setIsLoading(false);
        console.log({ DataStore_Save_ErrorMessage: err })
        localDbSet(
          `error-${new Date().toISOString()}-DataStore_Save_ErrorMessage`,
          JSON.stringify(err)
        )
        return;
      });
    }

    setSaveThenCopy(false);
    setIsLoading(false);

    try {
      if (itemType === "asset" && saveThenCopy) {
        onClose(true, copyItem);
      } else {
        onClose(true);
      }
    } catch (error) {
      console.log(" XXXXXXXX catch after onClose err == ", error.message);
    }
  }

  function onAssetTypeDrawerChange(newAssetType) {
    form.setFieldsValue({
      assetType: newAssetType,
    });
    forceUpdate();
    setAssetDefinition();
    setIsAssetTypeDrawerVisible(false);
  }

  function onAssetTypeChange(selectedTreeNode) {
    const newAssetType = mapAssetType(selectedTreeNode[0]);
    if (isFabric && selectedTreeNode[0].selectorId) {
      setFabricSelectorId(selectedTreeNode[0].selectorId);
    }
    form.setFieldsValue({
      assetType: newAssetType,
    });
    forceUpdate();
    setAssetDefinition();
    setIsAssetTypeDrawerVisible(false);
  }

  let title = "";
  if (isFabricSection) {
    isNewItem = false;

    title = `Update ${hierarchy[0].type}`;
  } else {
    if (item) {
      isNewItem = false;

      title = form.getFieldValue("assetType")
        ? `Update ${itemType} - ${
            form.getFieldValue("assetType").knownAs ||
            form.getFieldValue("assetType").description
          }`
        : `Update ${itemType}`;
    } else {
      isNewItem = true;

      title = `Create ${itemType}`;
    }
  }

  function hasDates(item) {
    const updatedDate = item.updatedUser?.date || item.updatedUser?.updatedAt
    const createdDate = item.createdUser?.date || item.createdUser?.createdAt
    const dbSyncTime = item.updatedAt || item.createdAt
  
    return item ? (updatedDate || createdDate || dbSyncTime) : false
  }

  function itemCreatedOn(item) {
    if (item.createdUser && cleanDate.created(item.createdUser)){
      return `Created on ${formatDateTime(cleanDate.created(item.createdUser), true)}`;
    }
    if (item.createdAt) { 
      return `Created on ${formatDateTime(item.createdAt, true)}`;
    }
  }

  function oldFormatUpdatedAt(item) {
    if (item.createdUser) {
      if (cleanDate.created(item.createdUser))
        return `by ${setUserName(item.createdUser).substring(
          1
        )} on ${formatDateTime(cleanDate.created(item.createdUser), true)}`;

      return `by ${setUserName(item.createdUser).substring(
        1
      )} on ${formatDateTime(item.updatedAt, true)}`;
    }

    if (item.createdBy && item.updatedAt)
      return `by ${item.createdBy} on ${formatDateTime(item.updatedAt, true)}`;

    if (item.createdBy && item.createdAt)
      return `${formatDateTime(item.createdAt, true)}`;

    if (item.updatedAt) return `${formatDateTime(item.updatedAt, true)}`;
    return `${formatDateTime(item.createdAt, true)}`;
  }

  function renderLeafDetails(leaf) {
    if (!leaf){ return null }
    return (<>
      <Typography.Text className="created-on" style={{marginTop: -20,paddingBottom: 12,  borderBottom: '1px solid #d9d9d9'}}>
        Replacement Cost: £{(leaf.replacementCost || 0).toFixed(2)}
        <br />
        CIBSE Lifecycle: {leaf.lifecycle || 0} years
      </Typography.Text>
    </>)
  }

  function handleDrawerMaskClose() {
    const currentFormValues = form.getFieldsValue(true);
    const formUpdated = !isEqual(defaultValues, currentFormValues);

    if (formUpdated) {
      setIsDirty(true);
    }
  }

  return (
    <Drawer
      title={title}
      placement="right"
      onClose={() => {
        handleDrawerMaskClose()
      }}
      visible={visible}
      className={cx("item-drawer", `item-drawer-${itemType}`)}
      forceRender
      closable={false}
    >
      {!item && !isFabricSection && itemType === "asset" && (
        <>
          {ASSET_FAST_MOVERS_FEATURE_FLAG ? (
            assetType8020Checked ? (
              <ShortList />
            ) : (
              <FullList />
            )
          ) : null}
          <SearchableTree
            variant={"form"}
            type={"single"}
            searchLabel={"Search asset type..."}
            onChange={onAssetTypeChange}
            value={form.getFieldValue("assetType")?.description}
            searchType={"field"}
            label={"Select asset type"}
            treeData={assetTypeTreeData}
            children={ASSET_FAST_MOVERS_FEATURE_FLAG && AssetType8020()}
          />
        </>
      )}
      {!requiresValidation && renderLeafDetails(assetDefinition)}
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={async (x)  => await onSubmit(x)}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        form={form}
        initialValues={getInitialValues()}
        className="form-group"
      >
        {requiresValidation ? (
          <AssetValidationStatusUpdate
            form={form}
            initialValues={getInitialValues()}
            onClose={() => onClose()}
            onNext={() => setRequiresValidation(false)}
            onSubmit={async (f) => onSubmit(f)}
          />
        ) : (
          <>
            {itemType !== "asset" || form.getFieldValue("assetType") ? (
              <DisplayFormFields
                assetDefinition={form.getFieldValue("assetType")}
                images={images}
                setImages={setImages}
                form={form}
                itemDefinition={itemDefinition}
                facets={itemType === "asset" ? window.facets : customFacetList}
                forceUpdate={forceUpdate}
                item={item}
                itemType={itemType}
                isFabricSection={isFabricSection}
                isNewItem={isNewItem}
                setIsAssetTypeDrawerVisible={setIsAssetTypeDrawerVisible}
                parents={parents}
                errors={errors}
              />
            ) : null}

            {displayErrors({
              errors,
              areErrorsVisible,
              setAreErrorsVisible,
            })}
            {isDirty && !errors && (
              <FormEditAlert onClose={() => setIsDirty(false)} />
            )}
            <div className="form-buttons">
              <Button
                type="transparent"
                onClick={() => {
                  onClose();
                }}
              >
                Cancel
              </Button>
              {itemType === "asset" || form.getFieldValue("assetType") ? (
                <ButtonPair
                  disabled={
                    !assetDefinition ||
                    !form.getFieldValue("assetType")
                  }
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                  label={item ? "Update" : "Save"}
                  options={
                    <Menu>
                    <Menu.Item
                      disabled={isFabricSection}
                      key={"saveAndCopyButton"}
                      onClick={() => {
                        setSaveThenCopy(true);
                        form.submit();
                      }}
                    >
                      Save and Copy
                    </Menu.Item>
                    </Menu>
                  }
                />
              ) : (
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                >
                  Save
                </Button>
              )}
            </div>
          </>
        )}
      </Form>

      {!requiresValidation && item && (item.createdAt || item.updatedAt || item.createdUser) && (
        <Typography.Text className="created-on">
          {itemCreatedOn(item)}
          {hasDates(item) && (
              <>
                <br />
                Last updated{" "}
                {item?.updatedUser && item.updatedUser?.familyName && item.updatedUser?.date
                  ? `by ${setUserName(item.updatedUser).substring(1)} on ${formatDateTime(item.updatedUser?.date, true)}`
                    : oldFormatUpdatedAt(item)}
              </>
            )}
        </Typography.Text>
      )}
      {isAssetTypeDrawerVisible && (
        <AssetTypeDrawer
          defaultValue={form.getFieldValue("assetType")}
          visible={true}
          onClose={() => setIsAssetTypeDrawerVisible(false)}
          treeData={assetTypeTreeData}
          onSubmit={onAssetTypeDrawerChange}
          children={ASSET_FAST_MOVERS_FEATURE_FLAG && AssetType8020()}
          eightyTwenty={assetType8020Checked}
          ASSET_FAST_MOVERS_FEATURE_FLAG={ASSET_FAST_MOVERS_FEATURE_FLAG}
        />
      )}
    </Drawer>
  );
}
