/**
 * Check user rules
 * @example
 * hasRole('surveyor', userRoles)
 * hasRole(['surveyor', 'customer']) // 'surveyor' or 'customer'
 * hasRole(['and', ['surveyor', 'customer']]) // 'surveyor' and 'customer'
 * hasRole(and('surveyor', 'customer')) // 'surveyor' and 'customer'
 * hasRole(or('surveyor', and('customer', 'fabric')) // 'surveyor' or ('customer' and 'fabric')
 * hasRole(and('surveyor', not('customer'))) // 'surveyor' and not 'customer'
 * @param role
 * @param roles
 * @returns {boolean}
 */
export const hasRole = (role, roles = []) => {
  if (Array.isArray(role)) {
    const [operator, restRoles] = role
    const checkRoles = ['and', 'or', 'not'].includes(operator) ? restRoles : role
    switch (operator) {
      case 'not':
        return checkRoles.every((i) => !hasRole(i, roles))
      case 'and':
        return checkRoles.every((i) => hasRole(i, roles))
      case 'or':
      default:
        return checkRoles.some((i) => hasRole(i, roles))
    }
  }
  return roles.includes(role)
}

export const or = (...args) => ['or', args]
export const and = (...args) => ['and', args]
export const not = (...args) => ['not', args]

export const ROLES = {
  LEAD: 'lead',
  PROJECT_MANAGER: 'pm', // Manage own projects | create by PM, Suppliers from the same account and REAMS admin only
  CUSTOMER: 'customer', // (Analyst) Client portal and analytics access
  SURVEYOR: 'surveyor', // (AV) Asset Verification Surveyor
  FABRIC: 'fabric', // (Condition) Condition Surveyor
  SUPPLIER: 'supplier',
  ADMIN: 'admin',
  APPROVER: 'approver',
  MOBILE_LITE: 'mobileLite',
  DATA_IMPORT: 'dataImport'
}
