import React, { useEffect, useState } from 'react'
import { Input, InputNumber, Select } from 'antd'
import { useDebounce } from 'use-debounce'
import { ChevronDownIcon } from 'components/Icons'
import { units } from './units'
import DutyRatingCapacityInputStyled from './DutyRatingCapacityInputStyled'

const DutyRatingCapacityInput = ({
  disabled,
  setFocus = () => {},
  onBlurTimeout = () => {},
  inputKey = 'facets_duty-rating-capacity',
  form = {},
  forceUpdate = () => {},
  step = 0.1,
  minimum = 0
}) => {
  const DRC_SPLIT_CHAR = ' '

  const [ disableUnitsOfMeasure, setDisableUnitsOfMeasure ] = useState(true)
  const [ valueForValidation, setValueForValidation ] = useState(undefined)
  const [ formFieldUpdated, setFormFieldUpdated ] = useState(false)

  const [ value ] = useDebounce (valueForValidation, 500)

  useEffect(() => {
    const currentValue = form.getFieldValue(inputKey)

    if (currentValue) {
      form.setFieldsValue({ [`${inputKey}-value`]: currentValue.split(DRC_SPLIT_CHAR)[0] || undefined })
      form.setFieldsValue({ [`${inputKey}-units`]: currentValue.split(DRC_SPLIT_CHAR)[1] || undefined })

      if (currentValue.split(DRC_SPLIT_CHAR)[0]) {
        setDisableUnitsOfMeasure(false)
      }

      forceUpdate()
    }
  }, [])

  useEffect(() => {
    if (disabled) {
      form.setFieldsValue({ [inputKey]: undefined })
      form.setFieldsValue({ [`${inputKey}-value`]: undefined })
      form.setFieldsValue({ [`${inputKey}-units`]: undefined })

      setDisableUnitsOfMeasure(true)
      setFormFieldUpdated(true)

      validateFields()
    }
  }, [ disabled ])

  useEffect(() => {
    if (formFieldUpdated) {
      validateFields()
    }
  }, [ value ])

  function validateFields() {
    form.validateFields([inputKey])

    forceUpdate()
  }

  function updateValueForValidation() {
    const val = form.getFieldValue(`${inputKey}-value`)
    const unit = form.getFieldValue(`${inputKey}-units`)

    form.setFieldsValue({ [inputKey]: `${val || ''}${DRC_SPLIT_CHAR}${unit || ''}`})

    if (!formFieldUpdated) {
      setFormFieldUpdated(true)
    }

    setValueForValidation(`${val || ''}${DRC_SPLIT_CHAR}${unit || ''}`)
  }

  function handleValueChange(val) {
    form.setFieldsValue({ [`${inputKey}-value`]: val })

    if (val === '') {
      form.setFieldsValue({ [`${inputKey}-units`]: undefined })

      setDisableUnitsOfMeasure(true)
    } else {
      setDisableUnitsOfMeasure(false)
    }

    updateValueForValidation()
  }

  function handleUnitChange(val) {
    form.setFieldsValue({ [`${inputKey}-units`]: val })

    updateValueForValidation()
  }

  return (
    <DutyRatingCapacityInputStyled
      isDisabled={disabled}
    >
      <div className="drc-input-wrapper">
        <Input
          className="drc-hidden-input"
          value={form.getFieldValue(inputKey)}
        />
        <InputNumber
          className="drc-input-value"
          defaultValue={form.getFieldValue(`${inputKey}-value`)}
          disabled={disabled}
          min={minimum}
          placeholder="Duty/Rating/Capacity (DRC)"
          step={step}
          value={form.getFieldValue(`${inputKey}-value`)}
          onChange={val => handleValueChange(val)}
          onFocus={setFocus}
          onBlur={(e) => {
            onBlurTimeout()
          }}
        />
      </div>
      <div className="drc-select-wrapper">
        <label className="label">DRC UoM <span className="label-mandatory">*</span></label>
        <Select
          allowClear
          className="drc-select-unit"
          defaultValue={form.getFieldValue(`${inputKey}-units`)}
          disabled={disableUnitsOfMeasure}
          options={units.map(u => ({
            label: u.label,
            value: u.value
          }))}
          placeholder="Select DRC UoM"
          showSearch
          suffixIcon={<ChevronDownIcon />}
          value={form.getFieldValue(`${inputKey}-units`)}
          onChange={val => handleUnitChange(val)}
          onBlur={(e) => e.stopPropagation()}
        />
      </div>
    </DutyRatingCapacityInputStyled>
  )
}

export default DutyRatingCapacityInput
