import React, { useContext, useEffect, useState } from 'react'
import { configureDataStore, onDataStoreEvent } from "helpers/dataStoreHelpers";
import { AUTO_LOGOUT_TIMER_MILLISECONDS } from 'config';
import useDataStoreSyncStatus from 'helpers/hooks/DataStoreSyncStatus';
import { DataStore } from '@reams/elias-store';
import { Hub, API } from "aws-amplify";
import Cookie from "js-cookie";
import { get as localDbGet, set as localDbSet } from "idb-keyval";
import { logOut } from "helpers/generalHelpers";
import { getCurrentSession } from "helpers/apiHelpers";
import { deviceImages } from "helpers/deviceImages";
import Facets from "helpers/facets.json";
import { getDataStoreAssets, initialAssetState } from './assetsHandler';
import { getDataStoreSpaces, initialSpacesState } from './spacesHandler';
import { getDataStoreFloors } from './floorsHandler';
import { getDataStoreFacilities } from './facilitiesHandler';
import { getDataStoreSites } from './sitesHandler';
import { getDataStoreRegions } from './regionsHandler';
import { fetchHierarchy, hierarchyExists, updateHierarchy } from 'helpers/hierarchyHelpers';
import { throttledRefreshImages } from 'helpers/imageHelpers';

const COOKIE_DOWNLOAD_ALL_IMAGES = 'inspectionsDownloadAllImages';
const COOKIE_DOWNLOAD_ALL_IMAGES_TOGGLED_ON = 'on';
const COOKIE_DOWNLOAD_ALL_IMAGES_TOGGLED_OFF = 'off';

window.targetFacilities = undefined;
window.activeImageDownloadOperationId = undefined;
window.facets = Facets;
window.downloadAllImages = true;

let subscriptionAsset;
let subscriptionSpace;
let subscriptionFloor;
let subscriptionFacility;
let subscriptionSite;
let subscriptionRegion;
let logoutTimer;

const EntityDataContext = React.createContext()

const useEntityData = () => useContext(EntityDataContext)

const EntityDataProvider = ({online, children}) => {
  const downloadAllImagesCookie = Cookie.get(COOKIE_DOWNLOAD_ALL_IMAGES);
  const enabledDownloadAllImages = !downloadAllImagesCookie || downloadAllImagesCookie === COOKIE_DOWNLOAD_ALL_IMAGES_TOGGLED_ON;

  const [user, setUser] = useState();
  const [facilitiesToDownload, setFacilitiesToDownload] = useState([]);
  const favouriteSpacesState = useState([]);
  const setFavouriteSpaces = favouriteSpacesState[1];
  const [floors, setFloors] = useState({});
  const [facilities, setFacilities] = useState();
  const [sites, setSites] = useState({});
  const [regions, setRegions] = useState({});
  const [assets, setAssets] = useState(initialAssetState);
  const [spaces, setSpaces] = useState(initialSpacesState);
  const [userData, setUserData] = useState();
  const [tenantData, setTenantData] = useState();
  const [allocatedFacilities, setAllocatedFacilities] = useState();
  const copyAssets = useState([]);
  const tableViewState = useState("table");
  const [switchTenantsActive, setSwitchTenantsActive] = useState(false);
  const [showSwitchAccount, setShowSwitchAccount] = useState(false);
  const [deletedSpaces, setDeletedSpaces] = useState([])
  const [downloadAllImages, setDownloadAllImages] = useState(enabledDownloadAllImages)
  const [nonAllocatedActiveFacilities, setNonAllocatedActiveFacilities] = useState([])

  const syncStatusHook = useDataStoreSyncStatus({
    restartDataStore,
    online,
  });

  useEffect(() => {
    onInitialLoad();

    var activityEvents = [
      "mousedown",
      "mousemove",
      "keydown",
      "scroll",
      "touchstart",
      "click",
      "keyup",
      "input"
    ];

    activityEvents.forEach(function (eventName) {
      window.addEventListener(eventName, resetLogoutTimer);
    });

    const removeListenerDataStore = Hub.listen("datastore", onDataStoreEvent);

    return () => {
      activityEvents.forEach(function (eventName) {
        window.removeEventListener(eventName, resetLogoutTimer);
      });
      removeListenerDataStore();
    };
  }, []);

  useEffect(() => {
    let assetImages = []
    let facilityImages = []
    let spaceImages = []

    if (downloadAllImages) {
      // Generate list of facility images for active tenant to be downloaded
      if (facilities && Object.keys(facilities).length) {
        const facilityModels = Object.values(facilities)

        facilityImages = facilityModels.map(f => ({
          facilityId: f.id,
          images: f.images
        }))
      }

      if (facilitiesToDownload.length && Object.keys(assets.items).length) {
        for (const facilityId of facilitiesToDownload) {
          const itemIds = Object.keys(assets.items)

          deviceImages.enableFacility(facilityId)

          if (deviceImages.pendingDownloadCount > 0) {
            deviceImages.handlePendingImages()
          } else {
            deviceImages.isProcessing = false
          }

          // Generate list of asset images for the facility
          for (const itemId of itemIds) {
            if (assets.items[itemId].images?.length) {
              assetImages.push({
                facilityId: assets.items[itemId].facilityId,
                images: assets.items[itemId].images
              })
            }
          }

          // Generate list of space images for the facility
          const spaceItems = Object.values(spaces?.items) || []

          spaceImages = spaceItems.filter(s => s.facilityId === facilityId).map(s => ({
            facilityId: s.facilityId,
            images: s.images || []
          }))
        }
      }

      throttledRefreshImages([ ...assetImages,  ...facilityImages, ...spaceImages ])

      if (downloadAllImages) {
        // Reinitialse downloading of all images
        window.downloadAllImages = true

        deviceImages.resumeProcessing()
      } else {
        // Prevent, or stop downloading any further images
        window.downloadAllImages = false
      }
    }
    
    updateDownloadAllImagesCookie(downloadAllImages)
  }, [ downloadAllImages, assets, facilities, spaces, facilitiesToDownload ])

  function getSharedHostname () {
    const hostname = window.location.hostname;
  
    if (hostname === 'localhost') {
      return hostname;
    }
  
    return hostname.substring(hostname.indexOf('.'));
  }

  function updateDownloadAllImagesCookie(downloadsEnabled) {
    if (downloadsEnabled) {
      Cookie.set(COOKIE_DOWNLOAD_ALL_IMAGES, COOKIE_DOWNLOAD_ALL_IMAGES_TOGGLED_ON, {
        expires: 999999,
        domain: getSharedHostname()
      })
    } else {
      Cookie.set(COOKIE_DOWNLOAD_ALL_IMAGES, COOKIE_DOWNLOAD_ALL_IMAGES_TOGGLED_OFF, {
        expires: 999999,
        domain: getSharedHostname()
      })
    }
  }

  function resetLogoutTimer() {
    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }

    logoutTimer = setTimeout(() => {
      if (window.location.hostname === "localhost") {
        alert("you would be logged out now");
      } else {
        logOut(syncStatusHook.isOnline);
      }
    }, AUTO_LOGOUT_TIMER_MILLISECONDS);
  }

  async function setHierarchy(tenantId){
    const exists = await hierarchyExists(tenantId)
    
    if (!exists) {
      await updateHierarchy(tenantId)
      return;
    }
  
    const hierarchy = await fetchHierarchy()
    window.hierarchy = hierarchy
}

async function fetchFromAPIOrLocalDB(api, body, localDB){
  let data;
  try {
    data = await API.post("rest", api, {
      body,
      response: false,
    });
    await localDbSet(localDB, JSON.stringify(data));
  } catch (e) {
    let userDataString = await localDbGet(localDB);
    if (userDataString) {
      data = JSON.parse(userDataString);
    }
  }
  return data
}

async function calculateFacilitiesToDownloadFromAppSync({allocatedItems, tenantId}) {
  const { sites, regions, facilities } = allocatedItems;

  const facilitiesToDownload = await localDbGet("facilitiesToDownload") || {};
  
  const isOldFormat = Array.isArray(
    facilitiesToDownload
  );

  /**
   * v1 format stores facilities in a flat array
   */
  if (isOldFormat) {
    const prevActiveFacilities = facilitiesToDownload.map((x) => {
      return { regionId: undefined, siteId: undefined, facilityId: x };
    }).filter((f) =>
      facilities.includes(f.facilityId)
    );

    await localDbSet("facilitiesToDownload", {
      [tenantId]: prevActiveFacilities,
    });
    return prevActiveFacilities.map((f) => f.facilityId);
  } 
  
  /**
   * v2 format to be stored in an object of tenantId and locations in array of regions, sites, facilities
   */
  const previousFacilitiesData = facilitiesToDownload;

  let currentTenantData = facilitiesToDownload[tenantId] || [];
  let previousTenantActivatedFacilities = []

  if (currentTenantData.length) {
    for (const c of currentTenantData) {
      const { facilityId } = c

      if (facilityId) {
        previousTenantActivatedFacilities.push(facilityId)
      }
    }

    setNonAllocatedActiveFacilities(previousTenantActivatedFacilities)
  }

  const removeIncompleteFacility = currentTenantData.filter(
    (x) =>
      x.facilityId && x.siteId !== undefined && x.regionId !== undefined
  );

  if (currentTenantData.length !== removeIncompleteFacility.length) {
    await localDbSet("facilitiesToDownload", {
      ...previousFacilitiesData,
      [tenantId]: removeIncompleteFacility,
    });
  }

  if (
    sites.length !== 0 ||
    regions.length !== 0 ||
    facilities.length !== 0
  ) {
    currentTenantData = currentTenantData.filter(
      (x) =>
        allocatedItems.sites.includes(x.siteId) ||
        allocatedItems.regions.includes(x.regionId) ||
        allocatedItems.facilities.includes(x.facilityId) ||
        previousTenantActivatedFacilities.includes(x.facilityId)
    );
  }

  return removeIncompleteFacility.map((f) => f.facilityId);
}

  async function onInitialLoad() {
    const userSession = await getCurrentSession();
    const email = userSession.idToken.payload.email
    const userData = await fetchFromAPIOrLocalDB("/items/get-user-data", { email, useDynamoDB: true  }, 'get-user-data')
    const userTenants = await fetchFromAPIOrLocalDB("/items/get-user-tenants", {}, 'get-user-tenants')
    const cookieTenantId = Cookie.get("tenantId") || undefined
    const foundTenant = userTenants.find((tenant) => tenant.tenantId === cookieTenantId)
    const tenantId = foundTenant ? foundTenant.tenantId : userTenants[0].tenantId
    const tenantData = await fetchFromAPIOrLocalDB("/items/get-tenant-data", { tenantId, useDynamoDB: true }, 'get-tenant-data')
    const userAccess = await fetchFromAPIOrLocalDB("/items/get-user-access", { tenantId, email }, 'get-user-access')
  
    await setHierarchy(tenantId);

    window.tenantId = tenantId;

    if (userData.pingTimeoutOverrideMs) {
      window.pingTimeoutOverrideMs = userData.pingTimeoutOverrideMs
    }

    const allocatedItems = userAccess.locations || { sites: [], regions: [], facilities: [] };
    const filteredFacilityIds = await calculateFacilitiesToDownloadFromAppSync({allocatedItems, tenantId})
    const favouriteSpaces = await localDbGet("favourite-space") || [];

    window.targetFacilities = filteredFacilityIds;
    window.activeFacilities = {}

    if (downloadAllImages) {
      filteredFacilityIds.forEach(f => {
        deviceImages.enableFacility(f)
      })
    }

    setSwitchTenantsActive(userTenants.length > 1)
    setFacilitiesToDownload(filteredFacilityIds);
    setFavouriteSpaces(favouriteSpaces);
    setAllocatedFacilities(allocatedItems);
    setUser(userSession);
    setTenantData(tenantData);
    setUserData(userData);

    resetLogoutTimer();
  }

  useEffect(() => {
    if (allocatedFacilities || !tenantData) {
      restartDataStore();
    }
  }, [allocatedFacilities, tenantData, facilitiesToDownload, nonAllocatedActiveFacilities]);

  async function restartSubscriptions() {
    subscriptionAsset?.unsubscribe();
    subscriptionSpace?.unsubscribe();
    subscriptionFloor?.unsubscribe();
    subscriptionFacility?.unsubscribe();
    subscriptionSite?.unsubscribe();
    subscriptionRegion?.unsubscribe();

    if (facilitiesToDownload.length) {
      subscriptionAsset = getDataStoreAssets({
        setAssets, 
        tenantId: tenantData.tenantId, 
        facilitiesToDownload,
        downloadAllImages
      })
       
      subscriptionSpace = getDataStoreSpaces({
        setSpaces, 
        tenantId: tenantData.tenantId, 
        facilitiesToDownload,
        downloadAllImages
      })

      subscriptionFloor = getDataStoreFloors({
        setFloors, 
        tenantId: tenantData.tenantId, 
        facilitiesToDownload
      })
    } else if (assets.assetCount > 0) {
      setAssets(initialAssetState)
      setFloors({})
      setSpaces({})
    }

    subscriptionFacility = getDataStoreFacilities({
      setFacilities, 
      tenantId: tenantData.tenantId, 
      allocatedFacilities,
      nonAllocatedActiveFacilities,
    })

    subscriptionSite = getDataStoreSites({
      setSites, 
      tenantId: tenantData.tenantId, 
    })

    subscriptionRegion = getDataStoreRegions({
      setRegions, 
      tenantId: tenantData.tenantId, 
    })
  }

  async function restartDataStore() {
    if (!allocatedFacilities || !tenantData) return;

    await configureDataStore();
    await DataStore.stop();
    restartSubscriptions();
  }

  function revalidateAllocatedFacilities(facilityId) {
    // removes non allocated facilities on facility deactivation
    if (nonAllocatedActiveFacilities.includes(facilityId)) {
      setNonAllocatedActiveFacilities(
        nonAllocatedActiveFacilities.filter(f => f !== facilityId)
      );
    }
  }

  return (
    <EntityDataContext.Provider
      value={{
        restartDataStore,
        revalidateAllocatedFacilities,
        facilitiesToDownload, setFacilitiesToDownload,
        assets,
        spaces,
        facilities,
        sites,
        regions,
        floors,
        setAllocatedFacilities,
        allocatedFacilities,
        syncStatusHook,
        favouriteSpacesState,
        user,
        userData,
        tenantData,
        assetCount: assets.assetCount,
        copyAssets,
        tableViewState,
        switchTenantsActive, 
        setSwitchTenantsActive,
        showSwitchAccount, 
        setShowSwitchAccount,
        deletedSpaces,
        setDeletedSpaces,
        downloadAllImages,
        setDownloadAllImages
      }}>
      {children}
    </EntityDataContext.Provider>
  )
}

export default EntityDataProvider

export { useEntityData }
