import { pruneChildren } from "./parentChildHelpers";
import { set as localDbSet, get as localDbGet } from "idb-keyval";
import { API } from "aws-amplify";

export const updateHierarchy = async (tenantId) => {
  const latest = await downloadLatestHierarchy(tenantId)
  await saveHierarchy(tenantId, latest)
}

const downloadLatestHierarchy = async (tenantId) => {
  const hierarchyResponse = await API.post("rest", '/items/get-hierarchy', {
    body: { tenantId },
    response: false,
  });
  return JSON.parse(hierarchyResponse)
}

export const fetchHierarchy = async () => {
  const hierarchy = await localDbGet("hierarchy")
  return hierarchy
}

const saveHierarchy = async (tenantId, hierarchy) => {
  await localDbSet(
    'hierarchy',
    hierarchy
  )
  await localDbSet(
    'hierarchy-manifest',
    {tenantId, lastDownloaded: new Date().toISOString()}
  )
  window.hierarchy = hierarchy
}

export const fetchHierarchyManifest = async () => {
  const manifest = await localDbGet("hierarchy-manifest")
  return manifest
}

export const hierarchyExists = async (tenantId) => {
  const manifest = await fetchHierarchyManifest()
  if (!manifest) return false
  if (manifest.tenantId === tenantId) return true
  return false
}


export function getAntTreeFromGlobalHierarchy(
  globalHierarchy,
  filtered = false
) {
  let tree = JSON.parse(JSON.stringify(globalHierarchy));
  for (let i = 0; i < tree.length; i++) {
    convertTreeLevel({
      parent: tree[i],
      index: i,
      filtered,
      parents: [tree[i]],
    });
  }

  if (filtered) {
    tree = pruneChildren(tree);
  }
  return tree;
}

export function orderFacilitiesByLocation(facilities) {
  const structuredFacilities = {};

  facilities.forEach((facility) => {
    const rId = facility.regionId;
    const sId = facility.siteId;

    let region = structuredFacilities[rId];

    if (!region) {
      structuredFacilities[rId] = {};
    }

    if (
      structuredFacilities[rId] &&
      !structuredFacilities[rId].hasOwnProperty(sId)
    ) {
      structuredFacilities[rId] = { ...structuredFacilities[rId], [sId]: {} };
    }

    structuredFacilities[rId][sId] = {
      ...structuredFacilities[rId][sId],
      [facility.id]: facility,
    };
  });

  return structuredFacilities;
}

export function getAntTreeFromFacilities(
  facilities,
  spaces,
  currentSpace,
  floors,
  selectable = {
    facility: true,
    floor: true
  }
) {
  return facilities.map((facility) => ({
    type: "facility",
    title: facility.name,
    value: facility.id,
    key: facility.id,
    selectable: selectable.facility,
    children: getAntTreeFromFloors({
      currentSpace: currentSpace?.id,
      floors: (floors.groupByFacility[facility.id] || []).map(floorId => floors.items[floorId]), 
      spaces: spaces, 
      selectableFloor: selectable.floor
    }),
  })).sort((a, b) => a.title.localeCompare(b.title));
}

export function getAntTreeFromFloors({floors, spaces, currentSpace, selectableFloor = false}) {
  return floors
  .map((floor) => {
    return spaces.groupByFloor?.[floor.id]?.length ? ({
        type: "floor",
        title: floor.name,
        key: floor.id,
        selectable: selectableFloor,
        value: floor.id,
        facilityId: floor.facilityId,
        children: spaces.groupByFloor[floor.id]
          .map(spaceId => {
            const space = spaces.items[spaceId]

            return space ? {
              type: "space",
              title: space.name,
              value: space.id,
              key: space.id,
              selectable: true,
              spaceId: space.id,
              floorId: space.floorId,
              facilityId: space.facilityId,
            } : null
          })
          .filter(space => space?.value !== currentSpace)
          .sort((a, b) => a.title.localeCompare(b.title)),
        }) : null
      })
      ?.filter((x) => {
       return x?.children?.length > 0 
      }).sort((a, b) => a.title.localeCompare(b.title))
}

export function getAntTreeFavouriteSpaces(spaceIds, spaces) {
  const x = spaceIds.map((id) => {
    const item = spaces[id]
    
    return item ? {
      type: "space",
      spaceId: item.id,
      floorId: item.floorId,
      facilityId: item.facilityId,
      title: item.name,
      value: item.id,
      key: `favourite-${item.id}`,
      selectable: true,
    } : null
  }).filter(val => val);

  return [
    {
      title: "Favourite Spaces",
      key: "favourite-spaces",
      selectable: false,
      value: "favourite-spaces",
      children: x,
    },
  ];
}

function convertTreeLevel({ parent, index, parents, filtered = false }) {
  parent.title = parent.type;
  if (parent.nrm && !parent.children) {
    parent.title += ` - ${parent.nrm}`;
  }

  parent.label = parent.type;
  parent.key = `${parent.tree}-${index}-${parent.selectorId || ""}`;

  if (parent.children && parent.eliasID) {
    // if asset is also category
    const categoryIsAsset = JSON.parse(JSON.stringify(parent));
    delete categoryIsAsset.children;
    categoryIsAsset.tree.push(categoryIsAsset.type)
    parent.children = [categoryIsAsset, ...parent.children];
    delete parent.eliasID
  }

  if (parent.eliasID) {
    parent.key = parent.eliasID;
    if (parent.fastMover && filtered) {
      parent.found = true;
      parents.forEach((x) => {
        x.found = true;
      });
    }
  }
  parent.selectable = !parent.children || parent.children.length === 0;

  if (parent.children) {
    for (let i = 0; i < parent.children.length; i++) {
      convertTreeLevel({
        parent: parent.children[i],
        index: i,
        filtered,
        parents: [...parents, parent],
      });
    }
  }
}

export function getAssetDefinition(eliasID, parent) {
  for (const element of parent) {
    if (element.eliasID === eliasID) {
      return element;
    }
    if (element.children) {
      const child = getAssetDefinition(eliasID, element.children);
      if (child) {
        return child;
      }
    }
  }
}

export function convertGlobalHierarchyToDictionary({ parent, result }) {
  if (parent.eliasID) {
    result[parent.eliasID] = parent;
  }
  if (parent.children) {
    for (let child of parent.children) {
      convertGlobalHierarchyToDictionary({ parent: child, result });
    }
  }
  return result;
}

export function enrichFabricHierarchy({ parent, globalHierarchyDictionary }) {
  if (parent.eliasID) {
    let globalHierarchyElement = globalHierarchyDictionary[parent.eliasID];
    for (let key in globalHierarchyElement) {
      if (!["facets", "type"].includes(key)) {
        parent[key] = globalHierarchyElement[key];
      }
    }
  }
  if (parent.children) {
    for (let child of parent.children) {
      enrichFabricHierarchy({
        parent: child,
        globalHierarchyDictionary,
      });
    }
  }
}
