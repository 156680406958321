import { useState, useEffect, useMemo } from "react";
import { Typography, Modal, Menu, Alert, Checkbox } from "antd";
import {DataStore} from '@reams/elias-store';
import { useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { QuestionCircleOutlined, WarningOutlined } from "@ant-design/icons";
import { EditIcon, DeleteIcon, CopyIcon, MoveIcon } from "components/Icons";
import MoveAssetDrawer from "pages/SpaceDetailsPage/MoveAssetDrawer/MoveAssetDrawer";
import ItemDrawer from "components/ItemDrawer/ItemDrawer";
import FilterHeader from "components/FilterHeader/FilterHeader";
import { Space, Asset } from "models";
import Spinner from "components/Spinner/Spinner";
import CopyAssetPane from "./CopyAssetPane/CopyAssetPane";
import ItemTableGrid from "components/ItemTableGrid/ItemTableGrid";
import CopyAssetCompleteMessage from "./CopyAssetPane/CopyAssetCompleteMessage";
import { saveFavouriteSpaces } from "helpers/favouriteSpaceHelpers";
import FabricGroups from "components/FabricGroups/FabricGroups";
import Authorize from "components/Authorize/Authorize";
import { HiddenActionsInDropdown } from "components/ButtonPair/ActionsDropdown";
import {
  ASSET_COLUMN,
  useRenderGrid,
} from "../../helpers/asset/assetTableHelpers";
import _ from "lodash";
import "../../styles/customLinks.scss";
import {
  buildFilterTreeFromHierarchy,
  buildFilterableTreeByFacet,
  filterableOptions,
  treeObject,
} from "helpers/filters/assetFilterHelpers";
import {
  fetchFilterDescription,
  filterItem,
} from "helpers/filters/itemFilterHelpers";
import { useLocation } from "react-router-dom";
import { useEntityData } from "EntityDataContext/EntityDataContext";
import { getAssetDefinition } from "helpers/hierarchyHelpers";

export default function SpaceDetailsPage({
  pageHeader,
  addPageToHistory,
}) {
  const hierarchy = window.hierarchy;
  const { favouriteSpacesState, copyAssets, floors, spaces, assets: assetData, facilities, deletedSpaces, setDeletedSpaces } = useEntityData()

  const [sortBy, setSortBy] = useState("updatedAt");
  const [sortOrder, setSortOrder] = useState("descend");
  const [copyList, setCopyList] = copyAssets;
  const [favouriteSpaces, setFavouriteSpaces] = favouriteSpacesState;
  const [isCreatingNewAsset, setIsCreatingNewAsset] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState();
  const [copyAssetMessage, setCopyAssetMessage] = useState();
  const [movingAsset, setMovingAsset] = useState();
  const [copyAsset, setCopyAsset] = useState();
  const [movingAssetComplete, setMovingAssetComplete] = useState();
  const [editSpace, setEditSpace] = useState(false);
  const [filters, setFilters] = useState([]);
  const { spaceId } = useParams();
  
  const navigate = useNavigate();
  const location = useLocation();
  
  useEffect(() => {
    setSelectedAsset(location?.state?.asset);
  }, [location]);

  const space = spaces.items?.[spaceId]

  if (!space || deletedSpaces.indexOf(space.id) > -1) {
    navigate('/')
  }
  
  const facility = useMemo(() => {
    if (!space) return undefined

    return facilities?.[space.facilityId]
  }, [space, facilities])

  const floor = useMemo(() => {
    if (!space) return undefined

    return floors.items?.[space.floorId]
  }, [space, floors])


  const assets = useMemo(() => {
    if (!space || !floor || !facility) return []

    return (assetData?.assetTree?.[space.facilityId]?.[space.floorId]?.[space.id] || []).map(assetId => assetData.items[assetId])
  }, [assetData, spaceId, space, floor, facility])

  const setFiltersNode = (val) => {
    let desc = val;
    desc = fetchFilterDescription({filter: desc, facets: window.facets})
    const x = { id: val, description: desc };

    setFilters((old) => {
      if (old.find((o) => o.id === x.id)) {
        return [...old];
      }
      return [...old, x];
    });
  };

  const onFiltersChange = (node) => {
    // from select fields
    if (Array.isArray(node)) {
      node.map((node) => setFiltersNode(node));
      return;
    }

    // from option fields
    setFiltersNode(node.value);
  };

  const onFilterClose = (filter) => {
    setFilters((old) => {
      return old.filter((f) => f.id !== filter);
    });
  };

  useEffect(() => {
    if (spaces.items?.[spaceId]?.name) {
      addPageToHistory(spaces.items[spaceId].name);
    }
  }, [spaceId]);

  const enhancedAssets = useMemo(() => {
    let tmpAssets = assets?.map((asset) => {

      const assetDefinition = getAssetDefinition(
        asset.assetType?.eliasID,
        hierarchy
        );

      return {
        ...asset,
        name: assetDefinition?.knownAs || asset.assetType?.description,
        barcode: `${asset.facets?.barcode || ''} ${asset.notes?.barcode?.status ? `[${asset.notes?.barcode?.status}]` : ''}` ,
        quantity: asset.facets?.quantity,
        installDate: asset.facets && asset.facets["install-date"],
        condition: asset.facets?.condition,
        manufacturer: asset.facets?.manufacturer,
      };
    });
    
    return filterItem(tmpAssets, filters, window.facets);
  }, [assets, filters]);
  
  const fabricAssets = assets.filter(a => a.assetType?.fabricElement)

  const actions = (asset) => {
    return [
      {
        label: "Edit",
        key: "edit",
        onClick: (e) => {
          setSelectedAsset(asset);
        },
        icon: <EditIcon />,
      },
      {
        label: "Copy",
        key: "copy",
        onClick: () => {
          setCopyAsset(asset);
        },
        icon: <CopyIcon />,
      },
      {
        label: "Move",
        key: "move",
        onClick: () => {
          setMovingAsset(asset);
        },
        icon: <MoveIcon />,
      },
      {
        label: "Delete",
        key: "delete",
        onClick: () => confirmDeleteAsset(asset),
        icon: <DeleteIcon />,
      },
    ];
  };

  const filterTreeData = useMemo(() => {
    // search through facets.json and find appropriate facets
    const filterableFacets = window.facets.filter((i) => i.filterable);

    const nonRatingGroupFacets = filterableFacets.filter((i) => !i.ratingGroup);
    const ratingGroupFacets = filterableFacets.filter((i) => i.ratingGroup);

    const nonRGF = nonRatingGroupFacets.map((i) => {
      return buildFilterableTreeByFacet(
        assets,
        i,
        onFiltersChange,
        filters,
        onFilterClose
      );
    });

    const uniqueRGs = _.uniq(ratingGroupFacets.map((f) => f.ratingGroup));

    const rgTrees = uniqueRGs
      .map((rg) =>
        treeObject(
          rg,
          rg,
          ratingGroupFacets
            .filter((rgf) => rgf.ratingGroup === rg)
            .map((i) => {
              return buildFilterableTreeByFacet(
                assets,
                i,
                onFiltersChange,
                filters,
                onFilterClose
              );
            })
            .filter((f) => f)
        )
      )
      .filter((rg) => rg.children && rg.children.length > 0);

    const RGF =
      rgTrees.length > 0 ? [treeObject("Rating", "Rating", rgTrees)] : [];

    // build System filter
    const treeData = buildFilterTreeFromHierarchy(
      window.hierarchy,
      assets,
    );
    const SF = treeObject("System", "System", treeData);

    // build Asset Class filter
    const ACF = treeObject(
      "Asset Class",
      "Asset Class",
      filterableOptions(assets, { key: "Asset Class" })
    );

    return [SF, ACF, ...nonRGF, ...RGF];
  }, [assets, filters]);
  
  if (!space || !assets || !floor || !facility) {
    return <Spinner message="We are fetching and processing your data..." />;
  }

  async function confirmDeleteAsset(asset) {
    Modal.confirm({
      title: "Are you sure to delete this asset?",
      icon: <QuestionCircleOutlined />,
      okText: "Yes",
      cancelText: "No",
      onOk: async () => {
        const itemToDelete = await DataStore.query(Asset, asset.id);
        await DataStore.delete(itemToDelete);
      },
    });
  }

  const onConfirmDelete = (checked, modal) => {
    modal.update((prevConfig) => ({
      ...prevConfig,
      okButtonProps: {
        disabled: !checked
      }
    }));
  }

  async function confirmDeleteSpace(space) {
    const modal = Modal.confirm({
      title: "Delete Space",
      content:  
      <>
        <p>Are you sure you want to delete this space? Any assets belonging to this space will also be deleted.</p>
        <Checkbox onChange={(event) => onConfirmDelete(event.target.checked, modal)}>
            Yes, remove space and assets
        </Checkbox>
      </>,
      icon: <WarningOutlined />,
      okText: "Confirm",
      cancelText: "Cancel",
      okButtonProps: {
        disabled: true
      },
      onOk: async () => {
        const itemToDelete = await DataStore.query(Space, space.id);
        await DataStore.delete(itemToDelete);

        setDeletedSpaces((deleted) => {
          return [...deleted, itemToDelete.id]
        });

        const assetsToDelete = await DataStore.query(Asset, (x) =>
          x.spaceId("eq", space.id)
        );
        for (let i = 0; i < assetsToDelete.length; i++) {
          await DataStore.delete(assetsToDelete[i]);
          console.info(`Delete asset: ${assetsToDelete[i]}.name`);
        }
        navigate(`/floor/${space.floorId}`);
      },
    });
  }

  const columnTitles = {
    installDate: "Install Date",
    barcode: "Asset Id",
    updatedAt: "Last Edited",
  };

  const tableColumnTitleProps = {
    sortBy,
    setSortBy,
    sortOrder,
    setSortOrder,
    columnTitles,
  };

  const ASSET_COLUMNS = ASSET_COLUMN(
    tableColumnTitleProps,
    sortBy,
    sortOrder,
    (asset) => <HiddenActionsInDropdown actions={actions(asset)} />
  );

  return (
    <div className="space-details-page">
      <Helmet title={space?.name || "Space details"} />
      {pageHeader}
      <div className="content-header">
        <Typography.Title className="subtitle" level={1}>
          Space details - {space?.name}
        </Typography.Title>
        {copyAssetMessage && (
          <CopyAssetCompleteMessage
            copyAssetMessage={copyAssetMessage}
            setCopyAssetMessage={setCopyAssetMessage}
            space={space}
          />
        )}
        {movingAssetComplete && (
          <Alert
            message={`Asset ${movingAssetComplete.name} has been sucessfully moved`}
            description={`The asset ${movingAssetComplete.name} has been sucessfully moved to ${movingAssetComplete.space}`}
            type="success"
            showIcon
            afterClose={() => {
              setMovingAssetComplete();
            }}
            closable
          />
        )}

        <FilterHeader
          filterTreeData={filterTreeData}
          filterOnChange={(change) => {
            onFiltersChange(change);
          }}
          filterOnCloseTag={(closeTag) => onFilterClose(closeTag)}
          filterValue={filters}
          buttonPair={{
            label: "Add Asset",
            onClick: () => {
              setIsCreatingNewAsset(true);
            },
            options: (
              <Menu>
                <Menu.Item
                  key="favourite"
                  onClick={() =>
                    saveFavouriteSpaces(
                      setFavouriteSpaces,
                      favouriteSpaces,
                      space.id
                    )
                  }
                >
                  {favouriteSpaces.includes(space.id)
                    ? "Unfavourite"
                    : "Favourite"}
                </Menu.Item>
                <Menu.Item key="edit" onClick={() => setEditSpace(true)}>
                  Edit Space
                </Menu.Item>
                <Menu.Item
                  key="delete"
                  onClick={() => confirmDeleteSpace(space)}
                >
                  Delete Space
                </Menu.Item>
              </Menu>
            ),
          }}
          sortByOptions={ASSET_COLUMNS}
          {...tableColumnTitleProps}
        />
        <CopyAssetPane
          ASSET_COLUMNS={ASSET_COLUMNS}
          setCopyAsset={setCopyAsset}
          setCopyAssetMessage={setCopyAssetMessage}
        />
      </div>
      <Authorize allowedRoles={["admin", "fabric"]}>
        <FabricGroups
          parentType="space"
          parents={{ facility, floor, space }}
          fabricAssets={fabricAssets}
        />
      </Authorize>

      <Authorize allowedRoles={["surveyor", "admin", "supplier"]}>
        <ItemTableGrid
          title="Assets"
          items={enhancedAssets}
          columns={ASSET_COLUMNS}
          onClick={(a) => setSelectedAsset(a)}
          actions={actions}
          render={useRenderGrid}
        />
      </Authorize>

      {(selectedAsset || isCreatingNewAsset || copyAsset) && (
        <ItemDrawer
          onClose={(saveButton = false, x) => {
            if (copyList.includes(copyAsset?.id) && saveButton && copyAsset) {
              if (copyList.length === 1) setCopyAssetMessage(copyList[0]);

              setCopyList(copyList.filter((x) => x !== copyAsset.id));
            }
            setCopyAsset();
            setIsCreatingNewAsset(false);
            setSelectedAsset();
            if (x) {
              setCopyAsset(x);
            }
          }}
          visible={selectedAsset || isCreatingNewAsset || copyAsset}
          item={selectedAsset}
          copy={copyAsset}
          itemType="asset"
          parents={{ space, floor, facility }}
        />
      )}
      {movingAsset && (
        <MoveAssetDrawer
          onClose={() => {
            setMovingAsset(false);
          }}
          complete={setMovingAssetComplete}
          visible={movingAsset}
          asset={movingAsset}
          parents={{ space, floor, facility }}
        />
      )}
      {space && editSpace && (
        <ItemDrawer
          onClose={() => setEditSpace(false)}
          visible={space}
          item={space}
          parents={{ floor, facility }}
          itemType="space"
        />
      )}
    </div>
  );
}
