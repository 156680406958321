import React, { useState, useEffect } from 'react'
import cx from 'classnames'
import { Input, Button } from 'antd'
import { useDebounce } from 'use-debounce'
import './QuantityIncreaser.scss'

const QuantityIncreaser = (props) => {
  const MINIMUM = 1

  const { disabled, inputValue, onChange } = props

  const defaultValue = inputValue && inputValue >= 1 ? inputValue : MINIMUM

  const [ quantity, setQuantity ] = useState(defaultValue)
  const [ isUpdated, setIsUpdated ] = useState(false)
  const [ value ] = useDebounce (quantity, 750)

  useEffect(() => {
    if (isUpdated) {
      onChange(value)
    }
  }, [ value, isUpdated ] )

  return (
    <div className={cx('button-pair', 'input-input')}>
      <Button
        className="input-decreaser"
        disabled={value === MINIMUM}
        onClick={() => {
          setIsUpdated(true)
          setQuantity((qty) => qty - 1)
        }}
      >
        <span>-</span>
      </Button>
      <Input
        type="number"
        value={quantity}
        onClick={(e) => {
          e.stopPropagation();
        }}
        min={MINIMUM}
        onChange={(e) => {
          setIsUpdated(true)
          setQuantity(Number.parseInt(e.target.value))
        }}
        autoComplete="off"
      />
      <Button
        className="input-increaser"
        disabled={disabled}
        onClick={() => {
          setIsUpdated(true)
          setQuantity((qty) => qty + 1)
        }}
      >
        <span>+</span>
      </Button>
    </div>
  )
}

export default QuantityIncreaser
