import React, { useEffect, useMemo, useState } from "react";
import { Input, Tree, Typography } from "antd";
import { getAntTreeFromFacilities, getAntTreeFromFloors } from "helpers/hierarchyHelpers";
import { ChevronDownIcon, ChevronLeftIcon } from "components/Icons";

import "./SpacePicker.scss";
import { pruneChildren } from "helpers/parentChildHelpers";
import { useEntityData } from "EntityDataContext/EntityDataContext";

let tempExpandedKeys = [];
export default function SpacePicker({
  onChange,
  space,
  currentSpace,
  facilityLevel = false
}) {
  const {floors, spaces, facilities, facilitiesToDownload} = useEntityData()
  const treeData = useMemo(() =>{
    const facilitiesArray = facilitiesToDownload
      .map(fId => facilities?.[fId] || null)
      .filter(x => x)
    if (facilityLevel) {
      const selectableItems = {
        facility: false,
        floor: false,
      }

      return getAntTreeFromFacilities(facilitiesArray, spaces, currentSpace, floors, selectableItems)
    }

    return getAntTreeFromFloors({
      currentSpace: currentSpace.id,
      floors: Object.values(floors.items || {}), 
      spaces
    })

  }
  , [floors, spaces, currentSpace,facilitiesToDownload , facilities])

  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchResults, setSearchResults] = useState();

  useEffect(() => {
    if (treeData){
      const results = performSearch(treeData);

      setSearchResults(results);
    }
  }, [searchValue, treeData]);

  function performSearch(treeData) {
    let value = searchValue.toLowerCase();
    const annotatedTree = JSON.parse(JSON.stringify(treeData));
    tempExpandedKeys = [];
    annotatedTree.forEach((parent) =>
      annotateTree({ parent, value, parents: [] })
    );
    return pruneChildren(annotatedTree);
  }


  function annotateTree({ parent, value, parents }) {
    if (parent.title.toLowerCase().includes(value)) {
      parent.found = true;
      if (parents.length < 3 && value.length !== 0) {
        tempExpandedKeys.push(parent.key);
      }
      parents.forEach((x) => (x.found = true));
    } else {
      parent.found = false;
    }

    if (parent.children) {
      parent.children.forEach((child) =>
        annotateTree({ parent: child, value, parents: [...parents, parent] })
      );
    }
  }

  function onSelect(selectedTree, { selectedNodes }) {
    setIsOpen(false);
    const selectedNode = selectedNodes[0];
    onChange(selectedNode);
  }

  return (
    <div className="space-picker">
      <Typography.Text className="label">Target space</Typography.Text>
      <div className="dropdown" onClick={() => setIsOpen((isOpen) => !isOpen)}> 
        <Typography.Text className="asset-type-value">
          {space?.title || 'Select'}
        </Typography.Text>
        <ChevronDownIcon />

        {isOpen && (
          <div
            className="searchable-tree"
            onClick={(e) => e.stopPropagation()}
            onMouseDown={(e) => e.stopPropagation()}
            onMouseUp={(e) => e.stopPropagation()}
          >
            <Input
              className="search-input"
              value={searchValue}
              placeholder="Search space..."
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <Tree
              switcherIcon={
                <div>
                  <ChevronLeftIcon />
                </div>
              }
              onSelect={onSelect}
              treeData={searchResults}
            />
          </div>
        )}
      </div>
    </div>
  );
}
