import { useMemo } from "react";
import TableColumnTitle from "components/TableColumnTitle/TableColumnTitle";
import { BulbOutlined, WalletOutlined } from "@ant-design/icons";
import { Descriptions, Typography, Tooltip } from "antd";
import { formatDateTime, renderUpdatedAt } from "helpers/generalHelpers";
import QuantityIncreaser from "components/GridView/QuantityIncreaser/QuantityIncreaser";
import { changeQuantity } from "helpers/drawerHelpers";
import { getAssetDefinition } from "helpers/hierarchyHelpers";


function sortNullAlwaysLast(a, b, ascending = true) {
  if (a === null) {
    return ascending ? 1 : -1;
  }
  if (b === null) {
    return ascending ? -1 : 1;
  }
  if (a === b) {
    return 0;
  }
  return a < b ? -1 : 1;

}

export const ASSET_COLUMN = (
  tableColumnTitleProps,
  sortBy,
  sortOrder,
  actions
) => {
  return [
    {
      title: <TableColumnTitle {...tableColumnTitleProps} dataIndex="name" />,
      dataIndex: "name",
      key: "Name",
      width: 200,
      fixed: "left",
      render: (name, asset) => (
        <>
          {asset.assetType.virtual === true ? (
            <BulbOutlined />
          ) : (
            <WalletOutlined />
          )}{" "}
          <>{name}</>
          <br />
          {asset.notes?.description && (
            <Typography.Text
              style={{ maxWidth: 200 }}
              ellipsis={{
                tooltip: true,
              }}
            >
              {asset.notes?.description || null}
            </Typography.Text>
          )}
        </>
      ),
      sorter: (a, b) =>
        a.name?.toLowerCase() < b.name?.toLowerCase() ? -1 : 1,
      sortOrder: sortBy === "name" ? sortOrder : false,
    },
    {
      title: (
        <TableColumnTitle {...tableColumnTitleProps} dataIndex="barcode" />
      ),
      key: "Barcode",
      dataIndex: "barcode",
      sorter: (a, b) => {
        const aa = a.barcode ? `${a.barcode}`.toLowerCase() : null
        const bb = b.barcode ? `${b.barcode}`.toLowerCase() : null
        return sortNullAlwaysLast(aa, bb, sortOrder === 'ascend' ? true : false)
      },
      sortOrder: sortBy === "barcode" ? sortOrder : false,
    },
    {
      title: (
        <TableColumnTitle {...tableColumnTitleProps} dataIndex="manufacturer" />
      ),
      key: "Manufacturer",
      dataIndex: "manufacturer",
      render: (manufacturer, asset) => (
        <>
          <>{manufacturer}</>
          <br />
          {asset.facets?.model && (
            <Typography.Text
              style={{ maxWidth: 200 }}
              ellipsis={{
                tooltip: true,
              }}
            >
              {asset.facets?.model || null}
            </Typography.Text>
          )}
        </>
      ),
      sorter: (a, b) => {
        const aa = a.manufacturer ? `${a.manufacturer}`.toLowerCase() : null
        const bb = b.manufacturer ? `${b.manufacturer}`.toLowerCase() : null
        return sortNullAlwaysLast(aa, bb, sortOrder === 'ascend' ? true : false)
      },
      sortOrder: sortBy === "manufacturer" ? sortOrder : false,
    },
    {
      title: (
        <TableColumnTitle {...tableColumnTitleProps} dataIndex="installDate" />
      ),
      key: "Install Date",
      dataIndex: "installDate",
      render: (installDate) => {
        const yearOnly = new Date(installDate).getUTCFullYear();
        if (!installDate) return "";
        return yearOnly;
      },
      sorter: (a, b) => (a.installDate < b.installDate ? -1 : 1),
      sortOrder: sortBy === "installDate" ? sortOrder : false,
    },
    {
      title: (
        <TableColumnTitle {...tableColumnTitleProps} dataIndex="condition" />
      ),
      key: "Condition",
      dataIndex: "condition",
      sorter: (a, b) => (a.condition < b.condition ? -1 : 1),
      sortOrder: sortBy === "condition" ? sortOrder : false,
    },
    {
      title: (
        <TableColumnTitle {...tableColumnTitleProps} dataIndex="quantity" />
      ),
      key: "Quantity",
      dataIndex: "quantity",
      sorter: (a, b) => (a.quantity < b.quantity ? -1 : 1),
      sortOrder: sortBy === "quantity" ? sortOrder : false,
    },
    {
      title: (
        <TableColumnTitle {...tableColumnTitleProps} dataIndex="updatedAt" />
      ),
      key: "Last Edited",
      dataIndex: "updatedAt",
      render: (_, item) => {
        return renderUpdatedAt(item);
      },
      sorter: (a, b) => (a.updatedAt < b.updatedAt ? -1 : 1),
      sortOrder: sortBy === "updatedAt" ? sortOrder : false,
    },
    {
      title: "",
      fixed: "right",
      render: (asset) => {
        return <div className="row-actions">{actions(asset)}</div>;
      },
    },
  ];
};

export const useRenderGrid = (asset) => {
  const assetDefinition = useMemo(
    () => getAssetDefinition(asset.assetType.eliasID, window.hierarchy),
    [asset.assetType.eliasID]
  );

  return (
    <Descriptions
      column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
      size={"small"}
    >
      <Descriptions.Item label="Quantity" labelStyle={{ margin: "auto" }}>
        {asset.assetType.virtual === true ||
          assetDefinition?.allowMultiple ? (
          <QuantityIncreaser
            inputValue={asset.facets.quantity}
            onChange={(qty) => changeQuantity(qty, asset.id)}
          />
        ) : (
          asset.facets.quantity
        )}
      </Descriptions.Item>
      {asset.facets["barcode"] && (
        <Descriptions.Item label="Barcode">
          {asset.facets.barcode}
        </Descriptions.Item>
      )}
      <Descriptions.Item label="Install Date">
        {formatDateTime(asset.facets["install-date"])}
      </Descriptions.Item>
      {asset.facets["condition"] &&
        typeof asset.facets["condition"] === "string" && (
          <Descriptions.Item label="Condition">
            <Tooltip title={asset.notes?.condition}>
              {asset.facets?.condition}
            </Tooltip>
          </Descriptions.Item>
        )}
      {asset.facets["manufacturer"] && (
        <Descriptions.Item label="Manufacturer">
          {asset.facets["manufacturer"]}
        </Descriptions.Item>
      )}
      {asset.facets["model"] && (
        <Descriptions.Item label="Model">
          {asset.facets["model"]}
        </Descriptions.Item>
      )}
      <Descriptions.Item label="Last Edited">
        {renderUpdatedAt(asset)}
      </Descriptions.Item>
    </Descriptions>
  );
};