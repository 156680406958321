import styled from 'styled-components'

const DutyRatingCapacityInputStyled = styled.div`
  display: flex;
  align-items: end;

  .drc-input-wrapper {
    display: flex;
    width: 50%;
    margin-right: 14px;

    .drc-hidden-input {
      display: none !important;
    }

    .drc-input-value {
      display: flex;
      flex-grow: 1;
    }
  }

  .drc-select-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-left: 14px;

    .label {
      position: absolute;
      top: -24px;
      opacity: ${props => props.isDisabled ? 0.25 : 1};

      &-mandatory {
        color: #ff1945;
      }
    }
  }

  div.ant-select-disabled.ant-select-status-error {
    .ant-select-selector {
      border: 1px solid #ff1945 !important;
    }
  }
`

export default DutyRatingCapacityInputStyled
