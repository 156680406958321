import { CloseOutlined, EyeOutlined } from "@ant-design/icons";
import { Button, Card, Col, Row, Skeleton, Tooltip, Typography } from "antd"
import { API } from "aws-amplify";
import { useEffect, useState } from "react"
import Cookie from "js-cookie";
import { useEntityData } from "EntityDataContext/EntityDataContext";

function getSharedHostname(){
    const hostname = window.location.hostname
  
    if (hostname === "localhost") {
      return hostname
    }
  
    return hostname.substring(hostname.indexOf("."))
  }

export const fetchUserTenants = async () => await API.post("rest", "/items/get-user-tenants", {
    body: {},
    response: false,
})

const UseAvaliableTenants = () => {
    const { showSwitchAccount } = useEntityData()
    const [data, setData] = useState()

    async function fetchAvaliableTenants() {
        const data = await fetchUserTenants()
        setData(data)
    }

    useEffect(() => {
        if (!showSwitchAccount) return;
        fetchAvaliableTenants()
    }, [showSwitchAccount])

    return data
}

const SwitchAccount = () => {
    const { 
      facilitiesToDownload, 
      facilities, 
      showSwitchAccount, 
      setShowSwitchAccount, 
      syncStatusHook: { isSyncingData } 
    } = useEntityData()

    const tenants = UseAvaliableTenants()

    const facilitiesActivatedOnDevice = facilitiesToDownload.map(f => facilities[f.id])

    async function switchTenant(tenantId) {

        window.tenantId = tenantId
        Cookie.set("tenantId", tenantId, {
            domain: getSharedHostname(),
            expires: 999999,
        });
        document.location.href = "/";
    }

    if (!showSwitchAccount) return null

    function toolTipText() {
        if (isSyncingData || facilitiesActivatedOnDevice.length > 0) return 'Selection Disabled | You can\'t change account until all data is synced and all facilities have been deselected for your current account.'
        return 'Click on the eye to switch account.'
    }

    return (<div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Typography style={{ flexGrow: 1, fontSize: 16, fontWeight: 'bold' }}>
                Select an account to switch and make active
            </Typography>
            <div>
                <CloseOutlined onClick={() => setShowSwitchAccount(false)} />
            </div>
        </div>
        <br />

        <Row gutter={[16, 16]} type="flex" style={{ display: "flex", justifyContent: 'center' }}>
            {tenants && tenants.sort((a, b) => a.tenantName.localeCompare(b.tenantName)).map((tenant) => (<Col
                data-test-selector="gridlist_column"
                xs={12}
                sm={12}
                md={12}
                lg={6}
                xl={4}
                key={tenant.tenantId}
            >  <Tooltip
                title={toolTipText()}
                placement={'bottom'}
            >
                    <Card
                        className="grid-list-card"
                        style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            flexDirection: "column",
                            width: "100%",
                            height: "100%",
                        }}

                        cover={
                            <div style={{ height: 100, width: "100%", overflow: "hidden", padding: "1px" }}>
                                {!tenant.imageURL && (
                                    <Skeleton.Image
                                        style={{
                                            width: "100%",
                                            borderRadius: "16px 16px 0 0",
                                        }}
                                        className="grid-skeleton"
                                    />
                                )}
                                {tenant.imageURL && (
                                    <img
                                        style={{ borderRadius: "16px 16px 0 0" }}
                                        width={"100%"}
                                        alt="Tenant"
                                        src={tenant.imageURL}
                                    />
                                )}
                            </div>
                        }
                        actions={[<Button
                            type="clear"
                            icon={<EyeOutlined />}
                            disabled={isSyncingData || facilitiesActivatedOnDevice.length > 0}
                            onClick={() => switchTenant(tenant.tenantId)}
                        />
                        ]}
                    >
                        <Card.Meta
                            title={tenant.tenantName}
                            description={tenant.supplierName}
                        />
                    </Card>
                </Tooltip>
            </Col>))}
        </Row>


    </div>)

}


export default SwitchAccount