import React, { useState } from 'react'
import HelpLink from './components/HelpLink'
import InfoCircleOutlined from './components/Icons/InfoCircleOutlined.jsx'
import MailOutlined from './components/Icons/MailOutlined.jsx'
import PhoneReceiverOutlined from './components/Icons/PhoneReceiverOutlined.jsx'
import QuestionOutlined from './components/Icons/QuestionOutlined.jsx'
import { ELIAS_UK_HELPDESK_PHONE, ELIAS_USA_HELPDESK_PHONE } from "config";
import ContactHelpButtonStyled, { ContactHelpModalStyled } from './ContactHelpButtonStyled'

const ContactHelpButton = ({
  buttonZindex = 10,
  modalZindex = 1000,
  modalWidth = 360,
  modalBodyStyle = {},
  modalMaskStyle = {},
  modalStyle = {},
  position = 'bottom-left',
  positionOffsetX = 12,
  positionOffsetY = 12,
}) => {
  const [ showModal, setShowModal ] = useState(false)

  function handleShowModal() {
    setShowModal(true)
  }

  function handleCloseModal() {
    setShowModal(false)
  }

  return (
    <>
      <ContactHelpButtonStyled
        buttonZindex={buttonZindex}
        position={position}
        positionOffsetX={positionOffsetX}
        positionOffsetY={positionOffsetY}
        onClick={handleShowModal}
      >
        <div className="help-button__label">
          <QuestionOutlined />
          <span>Help</span>
        </div>
      </ContactHelpButtonStyled>
      <ContactHelpModalStyled
        bodyStyle={modalBodyStyle}
        closable={false}
        footer={[
          <button
            className="help-modal-close-button"
            onClick={handleCloseModal}
          >
            Close
          </button>
        ]}
        maskClosable={true}
        maskStyle={modalMaskStyle}
        open={showModal}
        style={modalStyle}
        title={null}
        width={modalWidth}
        wrapClassName="contact-help-modal-window"
        zIndex={modalZindex}
        onCancel={handleCloseModal}
      >
        <h1>Please check out our online tutorials or get in touch if you require help with ELIAS</h1>          
        <ul className="help-link-list">
          <li>
            <HelpLink
              className="help-link-list__link"
              icon={<InfoCircleOutlined />}
              openInNewTab
              styledAs="primary"
              text="ELIAS Knowledge Centre"
              url="https://help.eliasam.com/"
            />
            <span className="help-link-list__label">
              - online tutorials
            </span>
          </li>
          <li>
            <HelpLink
              className="help-link-list__link"
              icon={<PhoneReceiverOutlined />}
              styledAs="primary"
              text={ ELIAS_UK_HELPDESK_PHONE }
              url={`tel:${ELIAS_UK_HELPDESK_PHONE.replaceAll(' ', '')}`}
            />
            <span className="help-link-list__label">
              - ELIAS Helpdesk UK
            </span>
          </li>
          <li>
            <HelpLink
              className="help-link-list__link"
              icon={<PhoneReceiverOutlined />}
              styledAs="primary"
              text={ELIAS_USA_HELPDESK_PHONE}
              url={`tel:${ELIAS_USA_HELPDESK_PHONE.replaceAll(' ', '')}`}
            />
            <span className="help-link-list__label">
              - ELIAS Helpdesk USA
            </span>
          </li>
          <li>
            <HelpLink
              className="help-link-list__link"
              icon={<MailOutlined />}
              styledAs="primary"
              text="support@eliasam.com"
              url="mailto:support@eliasam.com"
            />
            <span className="help-link-list__label">
              - email Helpdesk
            </span>
          </li>
        </ul>
      </ContactHelpModalStyled>
    </>
  )
}

export default ContactHelpButton
