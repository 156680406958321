import { useState } from "react";
import { Watermark } from '@hirohe/react-watermark';
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import AppHeader from "components/AppHeader/AppHeader";
import AppSidebar from "components/AppSidebar/AppSidebar";
import HomePage from "pages/HomePage/HomePage";
import FacilityDetailsPage from "pages/FacilityDetailsPage/FacilityDetailsPage";
import FloorDetailsPage from "pages/FloorDetailsPage/FloorDetailsPage";
import SpaceDetailsPage from "pages/SpaceDetailsPage/SpaceDetailsPage";
import AllAssetsDetailsPage from "pages/AllAssetsDetailsPage/AllAssetsDetailsPage";
import PageHeader from "components/PageHeader/PageHeader";
import Spinner from "components/Spinner/Spinner";
import ContactHelpButton from "components/ContactHelpButton";

import "./App.scss";
import SwitchAccount from "components/SwitchAccount";
import { useEntityData } from "EntityDataContext/EntityDataContext";


export default function App() {
  const [pageHistory, setPageHistory] = useState([]);
  const [isCollapsed, setIsCollapsed] = useState(false);
  
  const {
    facilities,
    tenantData,
    userData
  } = useEntityData()

  if (!Object.keys(facilities || {}).length || !userData || !tenantData) {
    return <Spinner message="Getting user permissions. Please wait..." />
  }

  function addPageToHistory(pageName) {
    setPageHistory((oldHistory) => [pageName, ...oldHistory])
  }

  function removePagesFromHistory(pageCount) {
    setPageHistory((oldHistory) => {
      const newHistory = oldHistory.slice(pageCount + 1)
      return newHistory
    });
  }

  const pageRoutes = [
    "/*",
    "/assets",
    "/facility/:facilityId",
    "/floor/:floorId",
    "/space/:spaceId",
  ];

  const pageHeader = (
    <Routes>
      {pageRoutes.map((route) => (
        <Route
          key={route}
          path={route}
          element={
            <PageHeader
              isCollapsed={isCollapsed}
              setIsCollapsed={setIsCollapsed}
              pageHistory={pageHistory}
              removePagesFromHistory={removePagesFromHistory}
            />
          }
        />
      ))}
    </Routes>
  )

  return (
    <BrowserRouter>
      <div className="app">
        <Helmet
          titleTemplate="%s | ELIAS Inspections"
          title="ELIAS Inspections"
        />
        <Watermark text="UAT" opacity={0.5} textSize={19} gutter={10} textColor="#393939" show={false}>
          <AppHeader />
        </Watermark>
        <div className="app-help-wrapper">
          <ContactHelpButton />
        </div>
        <div className="app-content">
          <Routes>
            {pageRoutes.map((route) => (
              <Route
                key={route}
                path={route}
                element={
                  <AppSidebar
                    isCollapsed={isCollapsed}
                    setIsCollapsed={setIsCollapsed}
                  />
                }
              />
            ))}
          </Routes>
          <div className="page-content">
          <SwitchAccount />
            <Routes>
              <Route
                path="/assets/*"
                element={
                  <AllAssetsDetailsPage
                    pageHeader={pageHeader}
                  />
                }
              />
              <Route
                path="/facility/:facilityId/*"
                element={
                  <FacilityDetailsPage
                    addPageToHistory={addPageToHistory}
                    pageHeader={pageHeader}
                  />
                }
              />
              <Route
                path="/floor/:floorId/*"
                element={
                  <FloorDetailsPage
                    addPageToHistory={addPageToHistory}
                    pageHeader={pageHeader}
                  />
                }
              />
              <Route
                path="/space/:spaceId/*"
                element={
                  <SpaceDetailsPage
                    addPageToHistory={addPageToHistory}
                    pageHeader={pageHeader}
                  />
                }
              />
              <Route
                path="/"
                element={
                  <HomePage
                    addPageToHistory={addPageToHistory}
                    pageHeader={pageHeader}
                  />
                }
              />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </div>
        </div>
      </div>
    </BrowserRouter>
  );
}
